import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  Select,
} from "@mui/material"
import Controls from "./controls/Controls"
import DeleteIcon from "@mui/icons-material/Delete"
import db from "../Firestore"
import { useState } from "react"
import { aiIcon } from "../pages/services/colorServices"
import * as palette from "../components/symbols/palette"

const PropSpec = ({
  prop,
  setProp,
  handleDeleteProp,
  accountId,
  handleGetSuggestedTags,
  setElementType,
  suggestedTags,
  files,
  selectedFile,
  setSelectedFile,
}) => {
  const [existingShader, setExistingShader] = useState()

  const [isGettingTags, setIsGettingTags] = useState(false)

  const handleFindExistingProp = (e) => {
    const name = e.target.value

    // Check if name already as a shader name

    console.log("Check if name already as a shader name", { name, accountId })

    if (name !== "") {
      db.collection("shaders")
        .where("account_id", "==", accountId)
        .where("property", "==", name.toLowerCase())
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.size > 0) {
            console.log(
              "found existing shader(s)",
              querySnapshot.docs.map((doc) => doc.data().name)
            )
            setExistingShader(querySnapshot.docs[0].data())
          } else {
            setExistingShader(undefined)
          }
        })
    }
  }

  return (
    <Box>
      <Stack direction="column" spacing={2}>
        <Stack sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
          <Box>
            <Controls.TextInput
              label="Property Name"
              value={prop.name}
              onChange={(e) => setProp({ ...prop, name: e.target.value })}
              onBlur={handleFindExistingProp}
              autoComplete="off"
              helperText="Criticality, Importance, Complexity, etc."
            />
          </Box>

          <Box>
            <Tooltip title="Delete Property">
              <IconButton onClick={() => handleDeleteProp(prop.seq)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Stack>
        {existingShader && (
          <Box sx={{ pb: "15px" }}>
            <Alert severity="info" sx={{ mt: "5px", mb: "10px", p: "5px" }}>
              There is already a shader called <b>{existingShader.name}</b>. It
              has the following values:
              <b>
                {existingShader.config.colors.map((c) => c.value).join(", ")}
              </b>
              . Only add this property if it aligns with the existing property
              meaning.
            </Alert>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Controls.Button
                label="Use these values"
                onClick={() => {
                  setProp({
                    ...prop,
                    description: `Use the following values: ${existingShader.config.colors
                      .map((c) => c.value)
                      .join(", ")}`,
                  })
                }}
              />
            </Box>
          </Box>
        )}
        <Box>
          <Controls.TextInput
            label="Prompt"
            value={prop.description}
            onChange={(e) => setProp({ ...prop, description: e.target.value })}
            multiline={true}
            helperText="Prompt to generate property values"
          />
        </Box>

        <Box>
          <FormControl>
            <InputLabel id="file-select">File</InputLabel>
            <Select
              labelId="file-select"
              id="file-select-id"
              label="File"
              onChange={(e) => {
                setSelectedFile(e.target.value)
              }}
              variant="outlined"
              size="small"
              sx={{ width: "300px" }}
              value={selectedFile}
            >
              <MenuItem value={""}>None</MenuItem>
              {files.map((file) => {
                return (
                  <MenuItem key={file.name} value={file.name}>
                    {file.name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Box>

        <Stack direction="row" sx={{ alignItems: "center" }} gap={2}>
          <Tooltip title="Automatically suggest the best properties to add">
            <Button
              variant="outlined"
              size="small"
              sx={{ textTransform: "none" }}
              disabled={isGettingTags}
              onClick={() => {
                setIsGettingTags(true)
                handleGetSuggestedTags().then((result) => {
                  setIsGettingTags(false)
                })
              }}
            >
              Suggest properties
            </Button>
          </Tooltip>
          {isGettingTags && (
            <CircularProgress size={20} sx={{ color: aiIcon }} />
          )}
        </Stack>

        {suggestedTags.length > 0 && (
          <List>
            {suggestedTags.map((tag) => (
              <ListItemButton
                key={tag.name}
                onClick={() => {
                  setProp({
                    ...prop,
                    name: tag.name,
                    description: `${tag.prompt}. Provide a relative assessment where each value is applied at least once.`,
                  })
                  const elType = palette.getElementType(tag.element_type)
                  console.log("setElementType", {
                    tagElType: tag.element_type,
                    elType,
                  })
                  setElementType(elType.index)
                }}
              >
                <ListItemText
                  primary={
                    <Typography variant="body2" color="text.primary">
                      {tag.name} ({tag.element_type})
                    </Typography>
                  }
                  secondary={
                    <Typography variant="caption" color="text.secondary">
                      {tag.reason}
                    </Typography>
                  }
                />
              </ListItemButton>
            ))}
          </List>
        )}

        {/* <Box>
          <Controls.Checkbox
            label="Automatic Property Values"
            checked={prop.auto}
            onChange={(e) => setProp({ ...prop, auto: e.target.value })}
            helperText="OpenAI will automatically generate suitable values"
          />
          <Box>
            <Typography variant="caption" color="text.secondary">
              {prop.auto &&
                `Property values will be automatically generated. Do not specify any property values in the 'Description' field`}
              {!prop.auto &&
                `You should describe the property values that you require in the 'Description' field`}
            </Typography>
          </Box>
        </Box> */}
      </Stack>
    </Box>
  )
}

export default PropSpec
