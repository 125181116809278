import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Divider,
  LinearProgress,
  MenuItem,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material"
import Controls from "./controls/Controls"
import { useMemo, useState } from "react"
import {
  suggestElementType,
  GPT_4o_LATEST,
} from "../pages/services/chatGenerationServices"
import {
  createContent,
  getReferencedPromptViews,
} from "../pages/services/createContentServices"
import { useSnackbar } from "notistack"
import db from "../Firestore"
import { useEffect } from "react"
import {
  findMaxSeq,
  getLeafNodesOfElement,
  getLeafNodesOfView,
} from "../pages/services/modelEditServices"
import * as modelServices from "../pages/services/modelServices"
import CollapsablePanel from "./CollapsablePanel"
import * as palette from "./symbols/palette"
import LevelSpec from "./LevelSpec"
import { selectModelState, selectElementDefinitions } from "../redux/selectors"
import { setModelState } from "../redux/actions"
import { useSelector, useDispatch } from "react-redux"
import GPTModelSelect from "./GPTModelSelect"
import * as chatGenerationServices from "../pages/services/chatGenerationServices"
import YesNo from "./YesNo"

const ModelEditCreateContent = (props) => {
  const {
    accountId,
    selectedViewId,
    viewSet,
    handlePasteAdd,
    // Optional selected element, otherwise generated elements are for top level of view
    currentElement,
    currentView,
    setWaitingElementIds,
    generationPanelCount,
    setGenerationPanelCount,
    stopRequested,
    setGeneratingContentMessage,
    handleUpdatePromptHistory,
    views,
    roles,
    defaultLevelSpecs,
    assistants,
  } = props

  const [scope, setScope] = useState("")

  const modelCache = useSelector(selectModelState)

  const elementDefinitions = useSelector(selectElementDefinitions)

  const dispatch = useDispatch()

  const [overview, setOverview] = useState(viewSet?.overview || "")

  const [mode, setMode] = useState("basic")

  const [selectedGPTModel, setSelectedGPTModel] = useState(GPT_4o_LATEST)

  const [loadedViewsCache, setLoadedViewsCache] = useState([])

  const [prompts, setPrompts] = useState([])

  const [vectorStores, setVectorStores] = useState([])

  const [vectorStoreOptions, setVectorStoreOptions] = useState([])

  const [viewSetFiles, setViewSetFiles] = useState([])

  const [viewSetFileOptions, setViewSetFileOptions] = useState([])

  const [yesNoDeleteFileConfig, setYesNoDeleteFileConfig] = useState({
    title: "Delete Component?",
    description: "This delete is permanent",
    openPrompt: false,

    // this method is set when we prompt for deletion
    handleConfirm: null,
  })

  // A cache of view refs that we've already resolved, as the user is editing the prompt
  // Each entry in the array has a src, i.e. the [<viewref>] string, and a resolvedViewRef, which is the view object
  const [resolvedViewRefs, setResolvedViewRefs] = useState([])

  // Can be 'selected', or 'leaf' -- which is where content gets generated from
  const [attachTo, setAttachTo] = useState("leaf")

  const [selectedPromptId, setSelectedPromptId] = useState("")

  const { enqueueSnackbar } = useSnackbar()

  const BLANK_LEVEL_SPEC = {
    type: "", // palette.getIndex(palette.CAPABILITY),
    levels: 1,
    info: "",
    attrs: ["name"],
    qty: 10,
    qty_selection: chatGenerationServices.QTY_SELECTION_ANY,
    auto_qty: false,
    props: [],
    max_words: 25,
  }

  const [levelSpecs, setLevelSpecs] = useState([
    {
      ...BLANK_LEVEL_SPEC,
      seq: 1,
      props: [],
    },
  ])

  useEffect(() => {
    if (currentView) {
      setAttachTo(currentView.elements?.length === 0 ? "top" : "leaf")
    }
  }, [currentView])

  useEffect(() => {
    if (viewSet) {
      setScope(viewSet.scope)
    }
  }, [viewSet])

  useEffect(() => {
    if (accountId) {
      db.collection("vector_stores")
        .where("account_id", "==", accountId)
        .get()
        .then((querySnapshot) => {
          const options = []

          querySnapshot.forEach((doc) => {
            const data = doc.data()
            options.push({ id: doc.id, label: data.name, vs_id: data.vs_id })
          })
          setVectorStoreOptions(options.filter((item) => item.vs_id))
        })
    }
  }, [accountId])

  // Get view set files and create view set file options
  useEffect(() => {
    if (viewSet) {
      const viewSetFiles = viewSet.docs || []

      const options = viewSetFiles.map((file) => {
        return { id: file.file_id, label: file.name }
      })

      setViewSetFileOptions(options)
    }
  }, [viewSet])

  useEffect(() => {
    if (defaultLevelSpecs) {
      setLevelSpecs(defaultLevelSpecs)
    }
  }, [defaultLevelSpecs])

  const createModelCacheEntry = (model, fileName, parentId, type, name) => {
    const modelState = modelServices.createModelCacheItem(
      model,
      fileName,
      name,
      parentId,
      type
    )

    return modelState
  }

  // Parse the prompt and ensure any project or component references are loaded into modelCache
  const handleEnsureModelIsLoaded = async (prompt) => {
    const { loadedViews, missingViews } = await getReferencedPromptViews({
      accountId: accountId,
      prompt: prompt,
      views: views,
      modelCache: modelCache,
      resolvedViewRefs: resolvedViewRefs,
      loadedViewsCache: loadedViewsCache,
    })

    // console.log("%cgetReferencedPromptViews", "color:yellow", {
    //   loadedViews,
    //   missingViews,
    // })

    setLoadedViewsCache(loadedViews)

    if (missingViews.length === 0) {
      return
    }

    // Now load all the missing refs into cache
    for (const [index, ref] of missingViews.entries()) {
      // This callback defined in the for loop so we can reference attributes of the ref variable
      const loadModelIntoCache = (model, fileName, rawText, props) => {
        // console.log("%cloadModelIntoCache", "color:pink", {
        //   model,
        //   fileName,
        //   props,
        // })
        // Add a new cache item into the cache
        const modelState = createModelCacheEntry(
          model,
          ref.file,
          ref.id,
          ref.type,
          model.name
        )

        // console.log("%ccreated model state", "color:pink", {
        //   modelState,
        //   modelCache,
        // })
        // Replace the model cache with the updated state, incl. the newly added item
        dispatch(setModelState(modelState))
      }

      if (["project", "component"].includes(ref.type)) {
        const pathType = ref.type === "project" ? "projects" : "components"
        const filePath = `accounts/${accountId}/${pathType}/${ref.id}/`
        await modelServices.loadFile(
          filePath,
          ref.file,
          // Calls the above callback to add the model to the cache in Redux
          loadModelIntoCache,
          {
            createModelIndex: false,
          },
          false
        )
      }
    }
  }

  const handleClickCreateContent = async ({ roles }) => {
    if (!currentView) {
      console.log("no current view")
      return
    }

    if (!scope) {
      enqueueSnackbar(
        "Please define your scope, e.g. industry, program, project, capability",
        { variant: "warning" }
      )

      return
    }

    // Check all level specs have a type
    if (levelSpecs.some((levelSpec, index) => !levelSpec.type)) {
      enqueueSnackbar(`Please select an element type`, { variant: "warning" })
      return
    }

    const maxWords =
      chatGenerationServices.getAIUsageParam({
        roles,
        paramName: chatGenerationServices.MAX_DESCRIPTION_WORDS,
      }) || 50

    if (levelSpecs.some((levelSpec) => levelSpec.max_words > maxWords)) {
      enqueueSnackbar(
        `Max words for a description is ${maxWords}. Please reduce the number of words in your prompt`,
        { variant: "warning" }
      )
      return
    }

    // Check qty is <= 30 for 1st level, and <= 10 for 2nd and subsequent levels
    const firstLevelSpec = levelSpecs[0]
    const restLevelSpecs = levelSpecs.slice(1)

    //console.log("check qty", firstLevelSpec, restLevelSpecs)

    if (firstLevelSpec.qty > 50) {
      enqueueSnackbar("Max qty for 1st level is 50", { variant: "warning" })
      return
    }

    if (restLevelSpecs.some((levelSpec) => levelSpec.qty > 10)) {
      enqueueSnackbar("Max qty for 2nd and subsequent levels is 10", {
        variant: "warning",
      })
      return
    }

    const maxElementId = currentView.elements.reduce((maxId, element) => {
      if (element.id > maxId) {
        return element.id
      } else {
        return maxId
      }
    }, 0)

    stopRequested.value = false
    setGenerationPanelCount((curr) => curr + 1)

    const createContentProps = {
      accountId: accountId,
      modelCache: modelCache,
      scope: scope,
      overview: overview,
      viewSet,
      views,
      currentView: currentView,
      maxElementId: maxElementId,
      levelSpecs: levelSpecs,
      elementDefinitions: elementDefinitions,
      handlePasteAdd,
      setWaitingElementIds,
      setGeneratingContentMessage,
      gptModel: selectedGPTModel,
      roles: roles,
      vectorStores: vectorStores,
      viewSetFiles: viewSetFiles,
      assistants,
    }

    console.log("createContentProps", { createContentProps, attachTo })

    switch (attachTo) {
      case "top":
        const result = await createContent({
          ...createContentProps,
          stopRequested,
          currentElement: undefined,
        })
        if (result.error) {
          enqueueSnackbar(result.error, { variant: "error" })
          return
        }
        break

      case "selected":
        const resultSelected = await createContent({
          ...createContentProps,
          stopRequested,
          currentElement: currentElement,
        })
        if (resultSelected.error) {
          enqueueSnackbar(resultSelected.error, { variant: "error" })
          return
        }
        break

      case "leaf":
        const leafNodes = currentElement
          ? getLeafNodesOfElement(currentElement, currentView)
          : getLeafNodesOfView(currentView)
        let currentMaxId = maxElementId

        console.log("leaf nodes", leafNodes)

        let pastedElements

        for (const [index, leafNode] of leafNodes.entries()) {
          if (stopRequested.value === true) {
            console.log("stop requested")
            break
          }

          pastedElements = await createContent({
            ...createContentProps,
            stopRequested,
            currentElement: leafNode,
            currentView: {
              ...currentView,
              elements: index === 0 ? currentView.elements : pastedElements,
            },
            maxElementId: currentMaxId,
          })
          // If we clicked stop, then pastedElements will be undefined
          console.log("pastedElements", { pastedElements })
          if (pastedElements) {
            currentMaxId = pastedElements.reduce((maxId, element) => {
              if (element.id > maxId) {
                return element.id
              } else {
                return maxId
              }
            }, 0)
            console.log(
              "%cafter top loop",
              "color:yellow",
              {
                currentMaxId,
                pastedElements,
                ids: pastedElements.map((element) => element.id).join(", "),
              },
              leafNode.name
            )
          }
        }
        break

      default:
        console.log('attachTo not "top", "selected", or "leaf"')
        break
    }

    setWaitingElementIds([])
    setGeneratingContentMessage("")
    setGenerationPanelCount((curr) => curr - 1)
    handleUpdatePromptHistory({ levelSpecs })
  }

  const parentElements = useMemo(() => {
    if (!currentView) {
      return
    }

    const result = [
      {
        value: "top",
        toggleButton: (
          <ToggleButton key="top" value="top">
            Top
          </ToggleButton>
        ),
        message: (
          <Typography variant="caption">
            Attach to the top level of the view
          </Typography>
        ),
        // No parents. Elements to be generated will be top level and not attached to any parent(s)
        parents: undefined,
        showParents: false,
      },
    ]

    if (currentElement) {
      result.push({
        value: "selected",
        toggleButton: (
          <ToggleButton key="selected" value="selected">
            Selected
          </ToggleButton>
        ),
        message: (
          <Typography variant="caption">
            Attach as child elements under <b>{currentElement.name}</b>
          </Typography>
        ),
        parents: [currentElement],
        showParents: false,
      })
    }

    if (currentElement) {
      result.push({
        value: "leaf",
        toggleButton: (
          <ToggleButton key="leaf" value="leaf">
            Leaf
          </ToggleButton>
        ),
        message: (
          <Typography variant="caption">
            Content will be attached to the leaf elements of{" "}
            <b>{currentElement.name}</b>
          </Typography>
        ),
        parents: getLeafNodesOfElement(currentElement, currentView),
        showParents: true,
      })
    } else {
      result.push({
        value: "leaf",
        toggleButton: (
          <ToggleButton key="leaf" value="leaf">
            Leaf
          </ToggleButton>
        ),
        message: (
          <Typography variant="caption">
            Attach content to leaf elements
          </Typography>
        ),
        parents: getLeafNodesOfView(currentView),
      })
    }

    return result
  }, [currentElement, currentView])

  useEffect(() => {
    if (accountId) {
      db.collection("prompts")
        .where("account_id", "==", accountId)
        .get()
        .then((querySnapshot) => {
          const newPrompts = []
          querySnapshot.forEach((doc) => {
            newPrompts.push({ id: doc.id, ...doc.data() })
          })
          setPrompts(newPrompts)
        })
    }
  }, [accountId])

  useEffect(() => {
    if (currentElement) {
      const firstLevelSpec = levelSpecs[0]
      const restLevelSpecs = levelSpecs.slice(1)

      setLevelSpecs([
        { ...firstLevelSpec, type: currentElement.type },
        ...restLevelSpecs,
      ])
    }
  }, [currentElement])

  const handleSuggestElementType = async (levelSpec) => {
    const suggestedElementType = await suggestElementType({
      scope: scope,
      overview: overview,
      purpose: viewSet.purpose,
      prompt: levelSpec.info,
    })

    //console.log("suggestedElementType", { suggestedElementType, levelSpec })

    const elementType = palette.getElementType(suggestedElementType.type)
    suggestedElementType.id = elementType.index

    //console.log("get type id", { suggestedElementType, elementType })

    return suggestedElementType
  }

  const handleUseSelectedPrompt = () => {
    const selectedPrompt = prompts.find(
      (prompt) => prompt.id === selectedPromptId
    )
    if (selectedPrompt) {
      setLevelSpecs(selectedPrompt.levels)
    }
  }

  return (
    <>
      <YesNo config={yesNoDeleteFileConfig} />

      <Stack gap={2}>
        <Box>
          <EditMode mode={mode} setMode={setMode} />
        </Box>
        <Box sx={{ marginBottom: "10px" }}>
          {levelSpecs.length === 0 && <NoLevelSpecsAlert />}

          {mode === "extended" && (
            <Box sx={{ marginTop: "10px", padding: 1 }}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Edit prompt context (optional)
              </Typography>
              <Typography variant="caption" color="text.secondary">
                Modify the context provided to GPT-4 when generating content.
              </Typography>

              <CollapsablePanel title="Edit prompt context">
                <Stack direction="column" spacing={1} sx={{ gap: 2 }}>
                  <Controls.TextInput
                    label="Scope"
                    value={scope}
                    multiline
                    onChange={(e) => setScope(e.target.value)}
                    sx={{ width: "100%" }}
                    helperText="e.g. 1-3 words on industry, program, project, capability"
                  />

                  <Controls.TextInput
                    label="Overview"
                    value={overview}
                    multiline={true}
                    onChange={(e) => setOverview(e.target.value)}
                    sx={{ width: "100%" }}
                    helperText="An elaboration of the scope and context"
                  />
                </Stack>
              </CollapsablePanel>
            </Box>
          )}

          <Stack>
            {prompts.length === 0 && (
              <Typography variant="caption" color="text.primary">
                None found. Use the 'Prompts' menu.
              </Typography>
            )}
          </Stack>

          {mode === "extended" && (
            <Box sx={{ mt: "10px", padding: 1, width: "400px" }}>
              <GPTModelSelect
                selectedGPTModel={selectedGPTModel}
                setSelectedGPTModel={setSelectedGPTModel}
              />
            </Box>
          )}

          {/* {mode === "extended" && (
          <Paper
            sx={{ marginTop: "10px", padding: 1, width: "300px" }}
            elevation={2}
          >
            <Box sx={{ margin: "5px" }}>
              <Stack direction="column">
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  Import existing prompt (optional)
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  Import a pre-defined prompt. Defined under Config | AI
                  Designer Prompts
                </Typography>
                {prompts.length > 0 && (
                  <Stack direction="column" gap={2} sx={{ marginTop: "15px" }}>
                    <FormControl variant="standard" sx={{ minWidth: 100 }}>
                      <InputLabel id="prompts-label">
                        Existing AI Designer Prompts
                      </InputLabel>
                      <Select
                        labelId="prompts-label"
                        id="prompt-select"
                        sx={{ width: "260px" }}
                        variant="outlined"
                        size="small"
                        value={selectedPromptId}
                        onChange={(e) => setSelectedPromptId(e.target.value)}
                      >
                        {prompts.map((prompt) => (
                          <MenuItem key={prompt.id} value={prompt.id}>
                            {prompt.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>Select an existing prompt</FormHelperText>
                    </FormControl>
                    <Box sx={{ marginLeft: "auto" }}>
                      <Controls.Button
                        label="Import"
                        tooltip="Import and overwrite prompt details below with selected prompt"
                        onClick={handleUseSelectedPrompt}
                      />
                    </Box>
                  </Stack>
                )}
              </Stack>
            </Box>
          </Paper>
        )} */}

          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            {currentView &&
              levelSpecs.map((levelSpec, index) => (
                <Box key={levelSpec.seq}>
                  <LevelSpec
                    key={levelSpec.seq}
                    index={index}
                    accountId={accountId}
                    mode={mode}
                    modelCache={modelCache}
                    levelSpec={levelSpec}
                    setLevelSpec={(newLevelSpec) => {
                      setLevelSpecs((curr) => {
                        const newSpecs = [...curr]
                        newSpecs[index] = newLevelSpec
                        return newSpecs
                      })
                    }}
                    handleSuggestElementType={handleSuggestElementType}
                    handleDeleteLevelSpec={(seq) => {
                      setLevelSpecs((curr) =>
                        curr.filter((spec) => spec.seq !== seq)
                      )
                    }}
                    handleEnsureModelIsLoaded={handleEnsureModelIsLoaded}
                    currentElement={currentElement}
                    viewSet={viewSet}
                    views={views}
                    roles={roles}
                  />

                  <Stack sx={{ marginTop: "10px", maxWidth: "300px" }}>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      Add view reference to Level {index + 1} prompt
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      Select a view name below, which copies a reference to that
                      view into your prompt above. This allows your prompt you
                      are writing to refer to content in other views.
                    </Typography>
                    <Stack
                      direction="column"
                      gap={0}
                      sx={{ marginTop: "10px" }}
                    >
                      {views &&
                        views.map((view) => (
                          <MenuItem
                            key={view.id}
                            sx={{ padding: "3px", paddingLeft: "10px" }}
                            onClick={(e) => {
                              setLevelSpecs((curr) => {
                                const newSpecs = [...curr]
                                // Append the view name to the current levelSpec, wrapped in [ and ] to indicate it's a view name
                                const formattedViewName = `[design:${viewSet.name}:${view.name}]`

                                newSpecs[
                                  index
                                ].info = `${newSpecs[index].info}${formattedViewName}`
                                return newSpecs
                              })
                            }}
                          >
                            <Typography variant="caption">
                              {view.name}
                            </Typography>
                          </MenuItem>
                        ))}
                    </Stack>
                  </Stack>
                </Box>
              ))}
          </Box>

          {mode === "extended" && (
            <Box
              sx={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Controls.Button
                onClick={() =>
                  setLevelSpecs((curr) => [
                    ...curr,
                    { ...BLANK_LEVEL_SPEC, seq: findMaxSeq(curr) + 1 },
                  ])
                }
                label="Add Prompt"
                tooltip="Define another prompt to be run against the content generated by the previous prompt"
              />
            </Box>
          )}
        </Box>

        <Divider />

        <Stack gap={1} sx={{ marginTop: "10px" }}>
          {mode === "extended" && (
            <>
              <Box>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  Add Content To
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  Select where to attach the generated content
                </Typography>
              </Box>
              <ToggleButtonGroup
                color="primary"
                value={attachTo}
                exclusive
                size="small"
                onChange={(e) => setAttachTo(e.target.value)}
                aria-label="Attach To"
              >
                {parentElements &&
                  parentElements.map((item) => item.toggleButton)}
              </ToggleButtonGroup>

              <Box sx={{ maxWidth: "300" }}>
                {attachTo &&
                  parentElements.find((item) => item.value === attachTo)
                    ?.message}
              </Box>
            </>
          )}
          {/* <Box sx={{ maxWidth: "300px", marginLeft: "10px" }}>
                            <Typography variant="caption">
                                {attachTo &&
                                    parentElements
                                        .find((item) => item.value === attachTo)
                                        ?.parents?.map((parent) => parent.name)
                                        .join(", ")}
                            </Typography>
                        </Box> */}
        </Stack>

        <Box>
          <Autocomplete
            multiple
            id="vector_stores"
            options={vectorStoreOptions || []}
            autoComplete
            autoHighlight
            autoCorrect="on"
            size="small"
            value={vectorStores}
            onChange={(e, newValue) => {
              setVectorStores(newValue)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Vector Stores"
                variant="outlined"
                size="small"
                sx={{ maxWidth: "300px" }}
              />
            )}
          />
        </Box>

        <Box>
          <Autocomplete
            multiple
            id="view_set_files"
            options={viewSetFileOptions || []}
            autoComplete
            autoHighlight
            autoCorrect="on"
            size="small"
            value={viewSetFiles}
            onChange={(e, newValue) => {
              setViewSetFiles(newValue)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Files"
                variant="outlined"
                size="small"
                sx={{ maxWidth: "300px" }}
              />
            )}
          />
        </Box>

        <Box sx={{ marginTop: "20px", display: "flex", marginLeft: "auto" }}>
          {generationPanelCount > 0 && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
          <Button
            variant="contained"
            disabled={
              selectedViewId === "" ||
              levelSpecs.length === 0 ||
              generationPanelCount > 0
            }
            onClick={() => handleClickCreateContent({ roles })}
            sx={{ textTransform: "none" }}
          >
            Generate Content
          </Button>
        </Box>
      </Stack>
    </>
  )
}

const EditMode = ({ mode, setMode }) => {
  return (
    <ToggleButtonGroup
      color="primary"
      size="small"
      value={mode}
      exclusive
      onChange={(e) => setMode(e.target.value)}
      aria-label="Mode"
    >
      <ToggleButton
        value="basic"
        sx={{ textTransform: "none", fontSize: "12px" }}
      >
        Basic
      </ToggleButton>
      <ToggleButton
        value="extended"
        sx={{ textTransform: "none", fontSize: "12px" }}
      >
        Extended
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

const NoLevelSpecsAlert = () => {
  return (
    <Box sx={{ width: "300px" }}>
      <Alert severity="info" sx={{ marginTop: "10px" }}>
        <Typography variant="caption">
          Please define at least one level spec. Click <b>Add Level Spec</b>{" "}
          below
        </Typography>
      </Alert>
    </Box>
  )
}

export default ModelEditCreateContent
