import { Box, CircularProgress, Stack, Typography, colors } from "@mui/material"
import Controls from "./controls/Controls"
import { useRef, useState } from "react"
import DeleteIcon from "@mui/icons-material/Delete"
import SaveIcon from "@mui/icons-material/Save"
import { useSnackbar, withSnackbar } from "notistack"
import db from "../Firestore"
import * as dataServices from "../pages/services/dataServices"
import { useEffect } from "react"
import { useHistory, withRouter } from "react-router-dom"
import { spacing } from "../pages/services/styleServices"
import YesNo from "./YesNo"
import useClaims from "./useClaims"
import { createTemplate } from "../pages/services/templateBuilderServices"
import useAssistants from "./useAssistants"
import { aiIcon } from "../pages/services/colorServices"
import DescriptionIcon from "@mui/icons-material/Description"

const styles = {
  fab: {
    position: "fixed",
    bottom: 16,
    right: 16,
    top: "auto",
    left: "auto",
  },

  buttons: {
    marginTop: spacing(3),
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "5px",
    alignItems: "center",
  },
}

const TemplateEditForm = (props) => {
  const [templateId, setTemplateId] = useState(props.computedMatch.params.id)

  const { enqueueSnackbar } = useSnackbar()

  const history = useHistory()

  const [isProcessing, setProcessing] = useState(false)

  const assistants = useAssistants()

  const addFileRef = useRef(null)

  const [yesNoConfig, setYesNoConfig] = useState({
    title: "Delete",
    openPrompt: false,
    description: "Delete?",

    // Callback if user clicks 'Yes' to delete a child record.
    // We set the callback and label depending on what the user is deleting
    handleConfirm: null,
  })

  const [values, setValues] = useState({
    account_id: "",
    name: "",
    purpose: "",
    docs: [],
    tags: [],
    headings: [],
  })

  const { claims, accountId, roles } = useClaims({ forceRefresh: false })

  useEffect(() => {
    if (templateId) {
      db.collection("templates")
        .doc(templateId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const data = { ...values, ...doc.data() }
            setValues(data)
          } else {
            enqueueSnackbar("Template not found", { variant: "error" })
          }
        })
        .catch((error) => {
          enqueueSnackbar("Error getting template", { variant: "error" })
        })
    }
  }, [templateId])

  const isNew = () => templateId === undefined || templateId === ""

  const handlePromptConfirmDelete = (event) => {
    event.preventDefault()

    setYesNoConfig({
      title: "Delete Template?",
      openPrompt: true,
      description: "This delete is permanent. Are you sure?",
      handleConfirm: () => handleDeletePromptConfirmed(),
    })
  }

  const hideDeletePrompt = () => {
    const newConfig = {
      ...yesNoConfig,
      openPrompt: false,
    }
    setYesNoConfig(newConfig)
  }

  const handleDeletePromptConfirmed = async () => {
    hideDeletePrompt()

    if (templateId) {
      // Delete template
      db.collection("templates")
        .doc(templateId)
        .delete()
        .then(history.push("/templates"))
        .then(() => {
          enqueueSnackbar("Deleted", { variant: "success" })
        })
    }
  }

  const handleCreateTemplate = (event) => {
    setProcessing(true)

    createTemplate({
      name: values.name,
      purpose: values.purpose,
      assistants,
      roles,
    }).then((template) => {
      console.log("created template", { template })
      //setTemplateJson(template)
      setValues((curr) => ({ ...curr, headings: template.headings }))
      setProcessing(false)
    })
  }

  const handleSave = async (event) => {
    event.preventDefault()

    if (values.name === "") {
      enqueueSnackbar("Enter template name", { variant: "warning" })
    } else {
      if (isNew()) {
        const newTemplate = {
          ...values,
          account_id: claims.account_id,
          created: dataServices.serverTimestamp(),
          modified: dataServices.serverTimestamp(),
        }

        console.log("saving template", { newTemplate })

        await db
          .collection("templates")
          .add(newTemplate)
          .then((docRef) => {
            setTemplateId(docRef.id)
            history.replace(`/template/${docRef.id}`)
          })
          .then(() => {
            enqueueSnackbar("Created", {
              variant: "success",
            })
          })
      } else {
        const updateRecord = {
          ...values,
          modified: dataServices.serverTimestamp(),
        }

        console.log("saving record", { templateId, updateRecord })

        db.collection("templates")
          .doc(templateId)
          .update(updateRecord)
          .then(() => {
            enqueueSnackbar("Template saved", {
              variant: "success",
              vertical: "bottom",
              horizontal: "right",
            })
          })
      }
    }
  }

  return (
    <>
      <YesNo config={yesNoConfig} />

      {/* <YesNo config={yesNoDeleteFileConfig} /> */}

      <Box>
        <Stack
          direction="column"
          spacing={1}
          sx={{ marginTop: "20px", marginBottom: "20px", gap: 1 }}
        >
          <Controls.TextInput
            label="Template Name"
            value={values.name}
            onChange={(e) => setValues({ ...values, name: e.target.value })}
            sx={{ width: "350px" }}
          />

          <Controls.TextInput
            label="Purpose"
            value={values.purpose}
            multiline={true}
            onChange={(e) => setValues({ ...values, purpose: e.target.value })}
            sx={{ width: "500px" }}
          />

          {values.headings && values.headings.length > 0 && (
            <Box>
              <TemplateHeadings headings={values.headings} />
            </Box>
          )}

          {/* <Box>
            <pre>{JSON.stringify(values.headings, null, 2)}</pre>
          </Box> */}
        </Stack>

        <Box sx={styles.buttons}>
          {!isNew() && (
            <Controls.Button
              text="Delete"
              type="button"
              tooltip="Delete this template"
              endIcon={<DeleteIcon />}
              onClick={handlePromptConfirmDelete}
            />
          )}

          {!isNew() && (
            <Controls.Button
              type="button"
              text="Create"
              variant="contained"
              tooltip="Create a new template"
              endIcon={<DescriptionIcon />}
              onClick={handleCreateTemplate}
              disabled={assistants.length === 0 || isProcessing}
            />
          )}

          <Controls.Button
            type="button"
            text="Save"
            variant="contained"
            tooltip="Save template"
            endIcon={<SaveIcon />}
            onClick={(event) => handleSave(event)}
          />

          {isProcessing && (
            <Box sx={{ ml: "10px" }}>
              <CircularProgress size="20" sx={aiIcon} />
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}

const TemplateHeadings = ({ headings }) => {
  return (
    <Stack gap={0.5}>
      {headings &&
        headings.map((h) => {
          // parse the heading level, e.g. "1", "1.1", "2.3.1", etc to determine the
          // amount of indentation to apply

          if (!h.level) {
            return <></>
          }

          const indent = h.level.split(".").length - 1
          const headingProps =
            indent === 0
              ? {
                  fontWeight: "bold",
                  variant: "h5",
                  color: colors.lightBlue[600],
                }
              : indent === 1
              ? { fontWeight: "bold", variant: "h6", color: colors.grey[600] }
              : {}
          return (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
                key={h.level}
              >
                <Box sx={{ width: "50px" }}>
                  <Typography sx={headingProps}>{h.level}</Typography>
                </Box>
                <Box sx={{ ml: `${indent * 10}px` }}>
                  <Typography sx={headingProps}>{h.heading}</Typography>
                </Box>
              </Box>
              <Box sx={{ ml: `${50 + indent * 10}px` }}>
                <Typography variant="caption" color={colors.grey[600]}>
                  {h.rationale}
                </Typography>
              </Box>
            </Box>
          )
        })}
    </Stack>
  )
}

export default withSnackbar(withRouter(TemplateEditForm))
