import { Box, Stack } from "@mui/material"
import Controls from "./controls/Controls"
import { useState } from "react"
import DeleteIcon from "@mui/icons-material/Delete"
import SaveIcon from "@mui/icons-material/Save"
import { useSnackbar, withSnackbar } from "notistack"
import db from "../Firestore"
import * as dataServices from "../pages/services/dataServices"
import { useEffect } from "react"
import firebase from "firebase/compat/app"
import { useHistory, withRouter } from "react-router-dom"
import { spacing } from "../pages/services/styleServices"
import YesNo from "./YesNo"
import ArchiMateElementSelect from "./controls/ArchiMateElementSelect"
import * as palette from "./symbols/palette"

const styles = {
  fab: {
    position: "fixed",
    bottom: 16,
    right: 16,
    top: "auto",
    left: "auto",
  },

  buttons: {
    marginTop: spacing(3),
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "5px",
  },
}

const ElementPromptEditForm = (props) => {
  const [elementId, setElementId] = useState(props.computedMatch.params.id)

  const { enqueueSnackbar } = useSnackbar()

  const history = useHistory()

  const [yesNoConfig, setYesNoConfig] = useState({
    title: "Delete",
    openPrompt: false,
    description: "Delete?",

    // Callback if user clicks 'Yes' to delete a child record.
    // We set the callback and label depending on what the user is deleting
    handleConfirm: null,
  })

  const [values, setValues] = useState({
    account_id: "",
    name: "",
    prompt: "",
    type: "",
    created: dataServices.localTimestamp(),
    modified: dataServices.localTimestamp(),
  })

  useEffect(() => {
    if (elementId) {
      db.collection("elements")
        .doc(elementId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const data = doc.data()

            const newValues = {
              ...data,
              type: palette.getElementType(data.type).index,
            }

            setValues(newValues)
          } else {
            enqueueSnackbar("Prompt not found", { variant: "error" })
          }
        })
        .catch((error) => {
          enqueueSnackbar("Error getting prompt", { variant: "error" })
        })
    }
  }, [elementId])

  const isNew = () => elementId === undefined || elementId === ""

  const handlePromptConfirmDelete = (event) => {
    event.preventDefault()

    setYesNoConfig({
      title: "Delete Element?",
      openPrompt: true,
      description: "This delete is permanent. Are you sure?",
      handleConfirm: () => handleDeletePromptConfirmed(),
    })
  }

  const hideDeletePrompt = () => {
    const newConfig = {
      ...yesNoConfig,
      openPrompt: false,
    }
    setYesNoConfig(newConfig)
  }

  const handleDeletePromptConfirmed = async () => {
    hideDeletePrompt()

    if (elementId !== undefined && elementId !== "" && elementId !== null) {
      db.collection("elements")
        .doc(elementId)
        .delete()
        .then(history.push("/elementprompts"))
        .then(() => {
          enqueueSnackbar("Deleted", { variant: "success" })
        })
    }
  }

  const handleSave = async (event) => {
    event.preventDefault()

    if (values.name === "") {
      enqueueSnackbar("Enter element name", { variant: "warning" })
    } else {
      if (isNew()) {
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then(async (token) => {
            const newElement = {
              ...values,
              type: palette.getElementNameByIndex(values.type), 
              account_id: token.claims.account_id,
              created: dataServices.serverTimestamp(),
              modified: dataServices.serverTimestamp(),
            }

            console.log("saving element", { newElement })

            await db
              .collection("elements")
              .add(newElement)
              .then((docRef) => {
                setElementId(docRef.id)
                history.replace(`/elementprompt/${docRef.id}`)
              })
              .then(() => {
                enqueueSnackbar("Created", {
                  variant: "success",
                })
              })
          })
          .catch(function (error) {
            console.error("Error:" + error)
            //enqueueSnackbar("Error", { variant: "error " })
          })
      } else {
        const updateRecord = {
          ...values,
          type: palette.getElementNameByIndex(values.type),
          modified: dataServices.serverTimestamp(),
        }

        console.log("saving record", { elementId, updateRecord })

        db.collection("elements")
          .doc(elementId)
          .update(updateRecord)
          .then(() => {
            enqueueSnackbar("Element saved", {
              variant: "success",
              vertical: "bottom",
              horizontal: "right",
            })
          })
      }
    }
  }

  return (
    <>
      <YesNo config={yesNoConfig} />

      <Box sx={{ width: "600px" }}>
        <Stack
          direction="column"
          spacing={1}
          sx={{ marginTop: "20px", gap: 2 }}
        >
          <Controls.TextInput
            label="Name"
            value={values.name}
            onChange={(e) => setValues({ ...values, name: e.target.value })}
            sx={{ width: "100%" }}
          />

          <ArchiMateElementSelect
            label="Type"
            type={values.type}
            setType={(newType) => {
              setValues((curr) => {
                const newValues = {
                  ...curr,
                  type: newType,
                }
                const typeDef = palette.getElementTypeByIndex(newType)
                console.log("typeDef", typeDef)
                newValues.name = palette.formatLabel(typeDef.name)
                newValues.prompt = typeDef.label
                return newValues
              })
            }}
            sx={{ width: "100%" }}
          />

          <Controls.TextInput
            label="Prompt"
            value={values.prompt}
            multiline={true}
            onChange={(e) => setValues({ ...values, prompt: e.target.value })}
            sx={{ width: "100%" }}
          />
        </Stack>

        <Box sx={styles.buttons}>
          {!isNew() && (
            <Controls.Button
              text="Delete"
              type="button"
              tooltip="Delete this element"
              endIcon={<DeleteIcon />}
              onClick={handlePromptConfirmDelete}
            />
          )}

          <Controls.Button
            type="button"
            text="Save"
            tooltip="Save element"
            variant="contained"
            endIcon={<SaveIcon />}
            onClick={(event) => handleSave(event)}
          />
        </Box>
      </Box>
    </>
  )
}

export default withSnackbar(withRouter(ElementPromptEditForm))
