import React, { useState, useEffect, useRef } from "react"
import { Switch, Route, HashRouter } from "react-router-dom"
import "fontsource-roboto"
import "./index.css"
import SignIn from "./pages/SignIn"
import SignOut from "./pages/SignOut"
import SignUp from "./pages/SignUp"
import Dashboard from "./pages/Dashboard"
import ProfilePage from "./pages/ProfilePage"
import Invites from "./pages/Invites"
import AccountEdit from "./pages/AccountEdit"
import UserFind from "./pages/UserFind"
import UserEdit from "./pages/UserEdit"
import ComponentFind from "./pages/ComponentFind"
import ComponentEdit from "./pages/ComponentEdit"
import firebase from "firebase/compat/app"
import ProtectedRoute from "./ProtectedRoute"
import Billing from "./pages/Billing"
import ProjectEdit from "./pages/ProjectEdit"
import Print from "./pages/Print"
import ViewEdit from "./pages/ViewEdit"
import Projects from "./pages/Projects"
import ViewSets from "./pages/ViewSets"
import ElementView from "./pages/ElementView"
import ElementListView from "./pages/ElementListView"
import JobsToBeDone from "./pages/JobsToBeDone"
import Accounts from "./pages/Accounts"
import ProjectExplorer from "./pages/ProjectExplorer"
import ComponentExplorer from "./pages/ComponentExplorer"
import UserGuide from "./pages/UserGuide"
import StoryEdit from "./pages/StoryEdit"
import RuleEdit from "./pages/RuleEdit"
import RuleFind from "./pages/RuleFind"
import ML from "./pages/ML"
import QrTest from "./pages/QrTest"
import Stories from "./pages/Stories"
import Prompts from "./pages/Prompts"
import * as userServices from "./pages/services/userServices"
import ModelEdit from "./pages/ModelEdit"
import PromptEdit from "./pages/PromptEdit"
import StoryPrompts from "./pages/StoryPrompts"
import StoryPromptEdit from "./pages/StoryPromptEdit"
import db from "./Firestore"
import { obtainCustomClaims } from "./pages/services/cloudFunctions"
import { useSelector } from "react-redux"
import { selectElementDefinitions } from "./redux/selectors"
import { useDispatch } from "react-redux"
import { setElementDefinitions } from "./redux/actions"
import _ from "lodash"
import ElementPrompts from "./pages/ElementPrompts"
import ElementPromptEdit from "./pages/ElementPromptEdit"
import Templates from "./pages/Templates"
import TemplateEdit from "./pages/TemplateEdit"
import VectorStoreEdit from "./pages/VectorStoreEdit"
import VectorStores from "./pages/VectorStores"

const AppMenu = (props) => {
  const [isSystem, setSystem] = useState(false)

  const [roles, setRoles] = useState([])

  const [uid, setUid] = useState()

  const [accountId, setAccountId] = useState()

  const elementTypes = useSelector(selectElementDefinitions)

  const prevElementTypesRef = useRef()

  const dispatch = useDispatch()

  useEffect(() => {
    const unsub = firebase.auth().onAuthStateChanged((user) => {
      if (user !== null) {
        user.getIdTokenResult(true).then((token) => {
          setUid(token.claims.user_id)
          setSystem(token.claims.system_role === true)

          console.log("%cuid", "color:orange", { token, user })

          if (token.claims.roles) {
            setRoles(token.claims.roles)
          }

          if (token.claims.account_id) {
            setAccountId(token.claims.account_id)
          }

          const uid = user.uid
          userServices.logUserAccess(user.email, token.claims.account_id, uid)
        })
      }
    })

    return unsub
  }, [])

  // Listen for changes to elements
  // Do NOT include elementTypes in the useEffect variable list since
  // this is not required, and sends the useEffect into an infinite loop

  useEffect(() => {
    if (accountId && elementTypes) {
      const unsub = db
        .collection("elements")
        .where("account_id", "==", accountId)
        .onSnapshot((querySnapshot) => {
          const newElementTypes = [...elementTypes]

          querySnapshot.docChanges().forEach((change) => {
            const changedItem = { id: change.doc.id, ...change.doc.data() }

            console.log("%cchange", "color:yellow", change.type, changedItem)

            // Remove existing

            const index = newElementTypes.findIndex(
              (element) => element.id === changedItem.id
            )

            if (index !== -1) {
              newElementTypes.splice(index, 1)
            }
            if (change.type === "added") {
              newElementTypes.push(changedItem)
            }
            if (change.type === "modified") {
              newElementTypes.push(changedItem)
            }
          })

          // Check if any changes have occurred, so we can determine if we need to update the redux store

          // We need to sort the before and after, since the order of the elements in the array
          // can change, and we don't want to trigger a redux update if the order is the only thing that has changed

          const before = (
            prevElementTypesRef.current?.map((element) => {
              const { created, modified, ...rest } = element
              return rest
            }) || []
          ).sort((a, b) => a.id.localeCompare(b.id))

          const after = newElementTypes
            .map((element) => {
              const { created, modified, ...rest } = element
              return rest
            })
            .sort((a, b) => a.id.localeCompare(b.id))

          if (!_.isEqual(before, after)) {
            dispatch(setElementDefinitions(newElementTypes))
            prevElementTypesRef.current = newElementTypes
          }
        })

      return unsub
    }
  }, [accountId, elementTypes])

  useEffect(() => {
    if (!uid) {
      return
    }

    const unsub = db
      .collection("users")
      .doc(uid)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const data = doc.data()
          console.log("%cuser changed, update claims", "color:pink")
          obtainCustomClaims()
        }
      })

    return unsub
  }, [uid])

  const isSystemRole = () => isSystem

  const isRole = (roleName) => {
    //console.log("is role?", roleName, roles)
    return roles.includes(roleName)
  }

  const isComponentRole = () => isRole("comp_user") || isRole("comp_admin")

  const isProjectRole = () => isRole("project_admin") || isRole("project_user")

  const isEARole = () => isRole("ea_admin") || isRole("ea_user")

  const isAdminRole = () => isRole("admin")

  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" component={SignIn} allowAccess={true} />

        <Route path="/SignIn" component={SignIn} allowAccess={true} />

        <Route path="/QrTest" component={QrTest} allowAccess={true} />

        <Route path="/SignOut" component={SignOut} allowAccess={true} />

        <Route path="/SignUp" component={SignUp} allowAccess={true} />

        <Route path="/UserGuide" component={UserGuide} allowAccess={true} />

        <ProtectedRoute
          path="/dashboard"
          component={Dashboard}
          allowAccess={true}
        />

        <ProtectedRoute
          path="/billing/"
          component={Billing}
          allowAccess={() => isAdminRole()}
        />

        <ProtectedRoute
          path="/billing/:id"
          component={Billing}
          allowAccess={() => isAdminRole()}
        />

        <ProtectedRoute
          path="/profile"
          component={ProfilePage}
          allowAccess={true}
        />

        <ProtectedRoute
          path="/print/:type/:parentId"
          component={Print}
          allowAccess={isProjectRole()}
        />

        <ProtectedRoute
          path="/project/:id"
          component={ProjectEdit}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/project"
          component={ProjectEdit}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/projects"
          component={Projects}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/viewsets"
          component={ViewSets}
          allowAccess={() => isEARole()}
        />

        <ProtectedRoute
          path="/modeledit/:id"
          component={ModelEdit}
          allowAccess={() => isEARole()}
        />

        <ProtectedRoute
          path="/story/:id"
          component={StoryEdit}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/story"
          component={StoryEdit}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/stories"
          component={Stories}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/prompt/:id"
          component={PromptEdit}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/prompt"
          component={PromptEdit}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/prompts"
          component={Prompts}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/storyprompt/:id"
          component={StoryPromptEdit}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/storyprompt"
          component={StoryPromptEdit}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/storyprompts"
          component={StoryPrompts}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/elementprompt/:id"
          component={ElementPromptEdit}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/elementprompt"
          component={ElementPromptEdit}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/elementprompts"
          component={ElementPrompts}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/template/:id"
          component={TemplateEdit}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/template"
          component={TemplateEdit}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/templates"
          component={Templates}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/vectorstore/:id"
          component={VectorStoreEdit}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/vectorstore"
          component={VectorStoreEdit}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/vectorstores"
          component={VectorStores}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/ProjectExplorer"
          component={ProjectExplorer}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/ComponentExplorer"
          component={ComponentExplorer}
          allowAccess={() => isProjectRole()}
        />

        <Route
          path="/JobsToBeDone"
          component={JobsToBeDone}
          allowAccess={true}
        />

        {/* <ProtectedRoute
                    path="/component/:id"
                    component={ComponentEdit}
                    allowAccess={() => isComponentRole()}
                />

                <ProtectedRoute
                    path="/component"
                    component={ComponentEdit}
                    allowAccess={() => isComponentRole()}
                /> */}

        <ProtectedRoute
          path="/ml"
          component={ML}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/viewedit/:parentId/view/:viewId/fileName/:fileName"
          component={ViewEdit}
          allowAccess={true}
        />

        {/* Could be viewing an element from either a Component or Project */}
        <ProtectedRoute
          path="/elementview/:parentId/fileName/:fileName/elementId/:elementId"
          component={ElementView}
          allowAccess={() => isComponentRole() || isProjectRole()}
        />

        <ProtectedRoute
          path="/elementlist/:parentId/fileName/:fileName/type/:type"
          component={ElementListView}
          allowAccess={() => isComponentRole() || isProjectRole()}
        />

        {/* <ProtectedRoute
                    path="/projects"
                    component={ComponentFind}
                    allowAccess={() => isComponentRole()}
                /> */}

        {/* Edit a user */}
        <ProtectedRoute
          path="/useredit/:id"
          component={UserEdit}
          allowAccess={() => isAdminRole()}
        />

        <ProtectedRoute
          path="/users"
          component={UserFind}
          allowAccess={() => isAdminRole()}
        />

        {/* Edit a rule */}
        <ProtectedRoute
          path="/ruleedit/:id"
          component={RuleEdit}
          allowAccess={() => isAdminRole()}
        />

        {/* Create a new rule */}
        <ProtectedRoute
          path="/ruleedit/"
          component={RuleEdit}
          allowAccess={() => isAdminRole()}
        />

        <ProtectedRoute
          path="/rules"
          component={RuleFind}
          allowAccess={() => isAdminRole()}
        />

        <ProtectedRoute
          path="/invites"
          component={Invites}
          allowAccess={() => isAdminRole()}
        />

        <ProtectedRoute
          path="/accountedit/:id"
          component={AccountEdit}
          allowAccess={() => isSystemRole()}
        />

        <ProtectedRoute
          path="/accountedit"
          component={AccountEdit}
          allowAccess={() => isSystemRole()}
        />

        <ProtectedRoute
          path="/myaccount"
          component={AccountEdit}
          allowAccess={true}
        />

        <ProtectedRoute
          path="/accounts"
          component={Accounts}
          allowAccess={() => isSystemRole()}
        />

        {/* Edit a component */}
        <ProtectedRoute
          path="/component/:id"
          component={ComponentEdit}
          allowAccess={() => isComponentRole()}
        />

        {/* Create a new component */}
        <ProtectedRoute
          path="/component"
          component={ComponentEdit}
          allowAccess={() => isComponentRole()}
        />

        <ProtectedRoute
          path="/components"
          component={ComponentFind}
          allowAccess={() => isComponentRole()}
        />
      </Switch>
    </HashRouter>
  )
}

export default AppMenu
