// src/utils/markdownConverter.js

import { Schema } from "prosemirror-model"
import { DOMParser as ProseMirrorDOMParser } from "prosemirror-model"
import { schema as basicSchema } from "prosemirror-schema-basic"
import { addListNodes } from "prosemirror-schema-list"
import { marked } from "marked"

// 1. Define the horizontalRule node manually
const horizontalRule = {
  group: "block",
  parseDOM: [{ tag: "hr" }],
  toDOM: () => ["hr"],
}

// 2. Define the hardBreak node manually
const hardBreak = {
  inline: true,
  group: "inline",
  selectable: false,
  parseDOM: [{ tag: "br" }],
  toDOM: () => ["br"],
}

// 3. Clone the existing nodes from basicSchema without converting to a plain object
let nodes = basicSchema.spec.nodes

// 4. Remove the existing 'horizontal_rule' node to prevent conflicts
nodes = nodes.remove("horizontal_rule")

// 5. Add the manually defined 'horizontalRule' and 'hardBreak' nodes
nodes = nodes.append({
  horizontalRule,
  hardBreak,
})

// 6. Extend the schema with list nodes
nodes = addListNodes(nodes, "paragraph block*", "block")

// 7. Create the custom schema
const mySchema = new Schema({
  nodes,
  marks: basicSchema.spec.marks,
})

/**
 * Recursively maps mark types from 'strong' to 'bold' and 'em' to 'italic'.
 *
 * @param {object} node - The current node in the JSON structure.
 */
function mapMarkTypes(node) {
  if (!node || typeof node !== "object") return

  if (node.marks && Array.isArray(node.marks)) {
    node.marks = node.marks.map((mark) => {
      if (mark.type === "strong") {
        return { ...mark, type: "bold" }
      }
      if (mark.type === "em") {
        return { ...mark, type: "italic" }
      }
      return mark
    })
  }

  if (node.content && Array.isArray(node.content)) {
    node.content.forEach((child) => mapMarkTypes(child))
  }
}

/**
 * Recursively maps node types to match Tiptap's schema.
 *
 * @param {object} node - The current node in the JSON structure.
 */
function mapNodeTypes(node) {
  if (!node || typeof node !== "object") return

  // Map node types
  if (node.type === "ordered_list") {
    node.type = "orderedList"
  } else if (node.type === "bullet_list") {
    node.type = "bulletList"
  } else if (node.type === "list_item") {
    node.type = "listItem"
  } else if (node.type === "horizontal_rule") {
    node.type = "horizontalRule"
  } else if (node.type === "hard_break") {
    node.type = "hardBreak"
  }

  // Recursively process child nodes
  if (node.content && Array.isArray(node.content)) {
    node.content.forEach((child) => mapNodeTypes(child))
  }
}

/**
 * Converts a Markdown string to Tiptap-compatible JSON.
 *
 * @param {string} markdown - The Markdown content to convert.
 * @returns {object} - The ProseMirror JSON representation compatible with Tiptap.
 */
export function markdownToTiptapJSON(markdown) {
  try {
    // Convert Markdown to HTML using marked
    const html = marked(markdown)

    // Parse HTML into a DOM Node using the browser's DOMParser
    const parser = new DOMParser()
    const doc = parser.parseFromString(html, "text/html")

    // Use ProseMirror's DOMParser to parse the HTML into a ProseMirror Node
    const prosemirrorDoc = ProseMirrorDOMParser.fromSchema(mySchema).parse(
      doc.body
    )

    // Convert the ProseMirror document to JSON
    const json = prosemirrorDoc.toJSON()

    // Map mark types to match Tiptap's schema
    mapMarkTypes(json)

    // Map node types to match Tiptap's schema
    mapNodeTypes(json)

    return json
  } catch (error) {
    console.error("Error converting Markdown to Tiptap JSON:", error)
    // Return an empty document as a fallback
    return {
      type: "doc",
      content: [],
    }
  }
}
