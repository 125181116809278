import {
  addMessages,
  convertToMessages,
  GPT_4o_MINI_LATEST,
  runAssistant,
} from "./chatGenerationServices"
import { createThread, deleteThread, listMessages } from "./cloudFunctions"
import { v4 as uuidv4 } from "uuid"
import * as palette from "../../components/symbols/palette"

const suggestNextBestQuestions = async ({
  assistants,
  viewSet,
  views,
  setMessage,
}) => {
  const assistant = assistants.find((a) => a.name === "AIM Goal Seeker")

  const aimGoalSeekerAssistantId = assistant.id

  console.log('%cRetrieved assistant "AIM Goal Seeker"', "color:lightgreen", {
    assistant,
  })

  console.log("%cparams", "color:yellow", { assistants, viewSet, views })

  const suggestViewsFunction = assistant.tools.find(
    (t) => t.type === "function" && t.function.name === "suggest_views"
  )

  const threadResult = await createThread({
    messages: [],
  })

  const threadId = threadResult.data?.response?.id

  setMessage("Identifying next best views to create")

  const answeredQuestionIds = views
    .map((view) => view.question_id)
    .filter((id) => id)

  // Questions already suggested
  const existingSuggestedViewQuestionIds = viewSet.view_suggestions.map(
    (s) => s.question_id
  )

  console.log("answeredQuestionIds", {
    answeredQuestionIds,
    existingSuggestedViewQuestionIds,
    viewSet,
  })

  const answeredQuestions = views.filter((view) =>
    answeredQuestionIds.includes(view.question_id)
  )

  // not answered, and not suggested
  const unansweredQuestions = viewSet.view_questions
    .filter((question) => !answeredQuestionIds.includes(question.question_id))
    .filter(
      (question) =>
        !existingSuggestedViewQuestionIds.includes(question.question_id)
    )

  console.log("unansweredQuestions", {
    unansweredQuestions,
    answeredQuestions,
    existingSuggestedViewQuestionIds,
  })

  const messages = [
    `I want you to pick which questions you think are most important to answer next.`,
    `All of the questions exist to help achieve the objective of: ${viewSet.purpose}.`,
  ]

  messages.push(
    answeredQuestions.length > 0
      ? `The questions that have already been answered are:`
      : `No questions have been answered yet.`
  )

  messages.push(
    ...answeredQuestions.map(
      (question) => `ID [${question.question_id}]: ${question.question}`
    )
  )

  messages.push(
    `The questions that have already been suggested, and you do not need to suggest again are:`
  )

  messages.push(
    ...viewSet.view_suggestions.map((s) => `ID [${s.question_id}]: ${s.question}`)
  )
  messages.push(
    `The questions that have not been answered and from which you should identify what questions are most relevant and useful next towards achieving the stated objective`
  )

  messages.push(
    ...unansweredQuestions.map(
      (question) => `ID [${question.question_id}]: ${question.question}`
    )
  )

  await addMessages({ threadId, content: convertToMessages(messages) })

  const runResult = await runAssistant({
    threadId,
    assistantId: aimGoalSeekerAssistantId,
    usage: "suggest views",
    // Means that JSON function object has been created
    expectedStatus: "requires_action",
    functionToUse: suggestViewsFunction,
    modelName: GPT_4o_MINI_LATEST,
    tools: [{ type: "file_search" }],
  })

  console.log("%crun", "color:orange", { runResult })

  if (runResult.success === false) {
    console.error("Error in step run result", runResult.result.data.error)
    return
  }

  let cleanedUp
  if (runResult.result.data.response?.required_action.submit_tool_outputs) {
    const rawJson =
      runResult.result.data.response.required_action.submit_tool_outputs
        .tool_calls[0].function.arguments
    const viewJson = JSON.parse(rawJson)
    console.log("%cviews", "color:yellow", { viewJson })
    cleanedUp = cleanUpSuggestedViewsResult({
      nextBestViews: viewJson.next_best_views,
    })
    console.log("cleaned up", { cleanedUp })
  } else {
    const messageResult = await listMessages({ threadId: threadId })
    console.log("%cmessage result", "color:pink", {
      messageResult,
    })

    cleanedUp = []
  }

  const suggestedViewsWithUUIDsAndQuestions = cleanedUp
    .map((v) => ({
      ...v,
      view_id: `view-${uuidv4()}`,
      question: viewSet.view_questions.find(
        (q) => q.question_id === v.question_id
      )?.question,
    }))
    .filter((item) => item.question)

  console.log("%csuggested views with UUIDs", "color:yellow", {
    suggestedViewsWithUUIDs: suggestedViewsWithUUIDsAndQuestions,
  })

  if (threadId) {
    const deleteThreadResult = await deleteThread({ threadId: threadId })
    console.log("delete thread result", { deleteThreadResult })
  }

  return suggestedViewsWithUUIDsAndQuestions
}

const cleanUpSuggestedViewsResult = ({ nextBestViews }) => {
  const mergedViews = nextBestViews.map((v) => ({
    ...v,
  }))

  const filterOutInvalidArchiMateTypes = mergedViews.filter((view) => {
    // Check if invalid ArchiMate types are present

    const invalidTypes = view.element_types.filter((level) => {
      const type = level.type
      const elementType = palette.getElementType(type)
      if (elementType === undefined) {
        console.log("%cReject invalid element type:", "color:orange", type)
        return true
      }
      return false
    })

    if (invalidTypes.length > 0) {
      console.log(
        "%cfiltering out view due to invalid archimate types",
        "color:red",
        {
          view,
        }
      )
      return false
    }

    return true
  })

  const max2ElementTypesPerLevel = filterOutInvalidArchiMateTypes.map((v) => ({
    ...v,
    element_types: v.element_types.slice(0, 2),
  }))

  return max2ElementTypesPerLevel
}

export { suggestNextBestQuestions }
