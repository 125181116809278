// src/components/TiptapEditor.jsx

import React, { useEffect } from "react"
import { useEditor, EditorContent } from "@tiptap/react"
import StarterKit from "@tiptap/starter-kit"
import { Button } from "@mui/material"
import UnknownNode from "./UnknownNode"

const TiptapEditor = ({ content }) => {
  // Initialize the editor with the provided HTML content prop
  const editor = useEditor({
    extensions: [StarterKit, UnknownNode],
    content: content, // HTML string
    onUpdate: ({ editor }) => {
      const html = editor.getHTML()
      // Optionally, handle the updated HTML (e.g., save to a database)
      console.log("Updated HTML:", html)
    },
  })

  // Handle updates to the content prop
  useEffect(() => {
    if (editor && content) {
      editor.commands.setContent(content, false) // The second parameter prevents selection loss
    }
  }, [content, editor])

  // Remove the black border (outline) when the editor is focused
  useEffect(() => {
    if (editor) {
      const proseMirror = editor.view.dom
      // Remove the outline on focus
      proseMirror.style.outline = "none"

      // Ensure the outline stays removed when focused or clicked
      const handleFocus = () => {
        proseMirror.style.outline = "none"
      }

      proseMirror.addEventListener("focus", handleFocus)
      proseMirror.addEventListener("mousedown", handleFocus)

      // Clean up event listeners when the component unmounts
      return () => {
        proseMirror.removeEventListener("focus", handleFocus)
        proseMirror.removeEventListener("mousedown", handleFocus)
      }
    }
  }, [editor])

  if (!editor) {
    return null
  }

  // Inline styles for simplicity; consider using CSS classes or styled-components in production
  const styles = {
    editorContainer: {
      border: "1px solid #d3d3d3",
      borderRadius: "4px",
      padding: "8px",
      //maxWidth: '800px',
      margin: "20px auto",
      backgroundColor: "#fff",
    },
    toolbar: {
      display: "flex",
      borderBottom: "1px solid #d3d3d3",
      paddingBottom: "4px",
      marginBottom: "8px",
    },
    editor: {
      minHeight: "200px",
      outline: "none", // Remove default outline
      border: "none",
      boxShadow: "none",
      padding: "0",
      fontSize: "16px",
      lineHeight: "1.5",
      color: "#000",
    },
  }

  // Reusable ToolbarButton component
  const ToolbarButton = ({ onClick, isActive, ariaLabel, children }) => (
    <Button
      onMouseDown={(event) => {
        event.preventDefault() // Prevents the button from taking focus
        onClick() // Executes the formatting command
      }}
      aria-label={ariaLabel}
      size="small"
      sx={{
        backgroundColor: isActive ? "#e0e0e0" : "transparent",
        "&:hover": {
          backgroundColor: "#f0f0f0",
        },
        width: "30px",
        border: "none",
        padding: "8px",
        cursor: "pointer",
        fontSize: "16px",
        marginRight: "4px",
        borderRadius: "4px",
        transition: "background-color 0.3s",
      }}
    >
      {children}
    </Button>
  )

  return (
    <div style={styles.editorContainer}>
      {/* Toolbar */}
      <div style={styles.toolbar}>
        <ToolbarButton
          onClick={() => editor.chain().focus().toggleBold().run()}
          isActive={editor.isActive("bold")}
          ariaLabel="Toggle Bold"
        >
          <strong>B</strong>
        </ToolbarButton>
        <ToolbarButton
          onClick={() => editor.chain().focus().toggleItalic().run()}
          isActive={editor.isActive("italic")}
          ariaLabel="Toggle Italic"
        >
          <em>I</em>
        </ToolbarButton>
        {/* Add more buttons as needed */}
        <ToolbarButton
          onClick={() => editor.chain().focus().setHardBreak().run()}
          isActive={false} // hardBreak doesn't have an active state
          ariaLabel="Insert Line Break"
        >
          <span aria-hidden="true">&#8629;</span>{" "}
          {/* Unicode for line break symbol */}
        </ToolbarButton>
      </div>

      {/* Editor */}
      <EditorContent editor={editor} style={styles.editor} />
    </div>
  )
}

export default TiptapEditor
