import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Fab,
  IconButton,
  LinearProgress,
  Stack,
  Tab,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material"
import { useMemo, useRef, useState } from "react"
import ModelEditDashboardCard from "./ModelEditDashboardCard"
import * as palette from "./symbols/palette"
import AddIcon from "@mui/icons-material/Add"
import SportsScoreIcon from "@mui/icons-material/SportsScore"
import TravelExploreIcon from "@mui/icons-material/TravelExplore"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import AssistantIcon from "@mui/icons-material/Assistant"
import InsightsIcon from "@mui/icons-material/Insights"
import SortIcon from "@mui/icons-material/Sort"
import ClearIcon from "@mui/icons-material/Clear"
import GradingIcon from "@mui/icons-material/Grading"
import SearchIcon from "@mui/icons-material/Search"
import NotesIcon from "@mui/icons-material/Notes"
import { Packer } from "docx"
import { saveAs } from "file-saver"
import {
  checkModellingGoalCompleteness,
  createSingleSuggestedView,
  sortViewsForPresentation,
  generateInsightsAndActions,
  createViewSetContext,
  IS_AIM_TRIAL,
} from "../pages/services/chatGenerationServices"
import { generateQuestions } from "../pages/services/questionGenerationServices"
import { sortQuestions } from "../pages/services/questionSortingServices"
import * as documentGenerationServices from "../pages/services/documentGenerationServices"
import { suggestNextBestQuestions } from "../pages/services/suggestNextBestQuestionsServices"
import { aiColor } from "../pages/services/colorServices"
import GetAIMAIDialog from "./GetAIMAIDialog"
import LabelAndValue from "./LabelAndValue"
import { useSnackbar } from "notistack"
import SettingsIcon from "@mui/icons-material/Settings"
import DownloadIcon from "@mui/icons-material/Download"
import SellIcon from "@mui/icons-material/Sell"
import {
  setModelEditDashboardTab,
  setDashboardViewToggles,
} from "../redux/actions"
import { useDispatch } from "react-redux"
import {
  selectShowDashboardPrompts,
  selectModelEditDashboardTab,
  selectDashboardViewToggles,
} from "../redux/selectors"
import { useSelector } from "react-redux"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import ModelEditViewSuggestionCard from "./ModelEditViewSuggestionCard"
import ModelEditQuestion from "./ModelEditQuestion"
import ModelEditQuestionDialog from "./ModelEditQuestionDialog"
import { v4 as uuidv4 } from "uuid"
import {
  CONTEXT_TAB,
  QUESTIONS_TAB,
  SUGGESTED_VIEWS_TAB,
  MY_VIEWS_TAB,
  DOCUMENTS_TAB,
} from "../pages/services/modelEditDashboardServices"
import * as Roles from "../pages/services/roleServices"
import ModelEditMaxViewsReached from "./ModelEditMaxViewsReached"
import ModelEditDashboardDocumentsTab from "./ModelEditDashboardDocumentsTab"

const styles = {
  fab: {
    position: "fixed",
    bottom: 16,
    right: 16,
    top: "auto",
    left: "auto",
  },
}

const ModelEditDashboard = ({
  assistants,
  views,
  viewSet,
  setValues,
  viewSetId,
  handleAddSuggestedView,
  handleSelectView,
  aiRole,
  roles,
  setShowConfigureViewSet,
  viewSuggestions,
  setViewSuggestions,
  handleCreateViewFromSuggestion,
  handleUpdateViewsorting,
  handleUpdateInsightsAndActions,
  handleTogglePinQuestion,
  handleTogglePinSuggestedView,
  handleDeleteViewSuggestion,
  canAddView,
}) => {
  const [showBillingDialog, setShowBillingDialog] = useState(false)

  const [questionToEdit, setQuestionToEdit] = useState()

  const [showQuestionDialog, setShowQuestionDialog] = useState(false)

  const [generatingMessage, setGeneratingMessage] = useState(undefined)

  const [percentComplete, setPercentComplete] = useState(0)

  const selectedTabIndex = useSelector(selectModelEditDashboardTab)

  const dashboardPrompts = useSelector(selectShowDashboardPrompts)

  const [selectedQuestionId, setSelectedQuestionId] = useState()

  const viewElementToggles = useSelector(selectDashboardViewToggles)

  const [modellingCompleteness, setModellingCompleteness] = useState()

  const isPaid = useMemo(() => {
    return roles?.includes(Roles.AIM_AI)
  }, [roles])

  /**
   * return true if viewSet.view_questions is an array
   */
  const isViewQuestionsInJSONFormat = useMemo(() => {
    if (viewSet.view_questions && Array.isArray(viewSet.view_questions)) {
      return true
    }

    return false
  }, [viewSet])

  const isViewContextInJSONFormat = useMemo(() => {
    if (viewSet.view_context && Array.isArray(viewSet.view_context)) {
      return true
    }

    return false
  }, [viewSet])

  const dispatch = useDispatch()

  const { enqueueSnackbar } = useSnackbar()

  // useEffect(() => {
  //   console.log(
  //     "%csuggestedViewToShow",
  //     "color:lightGreen",
  //     suggestedViewToShow?.name
  //   )

  //   if (suggestedViewToShow) {
  //     console.log("%cshow suggested views tab", "color:yellow")
  //     dispatch(setModelEditDashboardTab(SUGGESTED_VIEWS_TAB))
  //   }
  // }, [suggestedViewToShow])

  const handleCheckModellingGoalCompleteness = async () => {
    if (!IS_AIM_TRIAL && !aiRole) {
      setShowBillingDialog(true)
      return
    }

    if (viewSet.purpose === "" || viewSet.overview === "") {
      enqueueSnackbar("Please ensure 'objective' and 'scope' are defined.", {
        variant: "warning",
      })
      return
    }

    setGeneratingMessage("Checking modelling completeness...")
    checkModellingGoalCompleteness({
      views,
      scope: viewSet.scope,
      overview: viewSet.overview,
      purpose: viewSet.purpose,
    }).then((result) => {
      console.log("modelling completeness", { result })
      setGeneratingMessage(undefined)
      setModellingCompleteness(result)
    })
  }

  const handleGenerateInsightsForSingleView = async ({ view }) => {
    if (!IS_AIM_TRIAL && !aiRole) {
      setShowBillingDialog(true)
      return
    }

    if (viewSet.purpose === "" || viewSet.overview === "") {
      enqueueSnackbar("Please ensure 'objective' and 'scope' are defined.", {
        variant: "warning",
      })
      return
    }

    setGeneratingMessage("Creating inights and actions...")
    const result = await generateInsightsAndActions({
      assistants,
      views: [view],
      scope: viewSet.scope,
      overview: viewSet.overview,
      purpose: viewSet.purpose,
      roles: roles,
    })

    console.log("sorted views", { result })

    setGeneratingMessage(undefined)
    handleUpdateInsightsAndActions({ diagramInfo: result.diagrams })
  }

  const handleSortViewsForPresentation = () => {
    if (!IS_AIM_TRIAL && !aiRole) {
      setShowBillingDialog(true)
      return
    }

    if (viewSet.purpose === "" || viewSet.overview === "") {
      enqueueSnackbar("Please ensure 'objective' and 'scope' are defined.", {
        variant: "warning",
      })
      return
    }

    setGeneratingMessage("Reordering views into presentation order...")
    sortViewsForPresentation({
      assistants,
      views,
      scope: viewSet.scope,
      overview: viewSet.overview,
      purpose: viewSet.purpose,
      roles: roles,
    }).then((result) => {
      console.log("sorted views", { result })
      setGeneratingMessage(undefined)
      handleUpdateViewsorting({ diagramInfo: result.diagrams })
    })
  }

  const handleClearViewSuggestions = () => {
    setViewSuggestions({
      suggestedViews: [],
      viewContext: undefined,
      viewQuestions: undefined,
    })
  }

  const handleShowInsights = (show) => {
    const newToggles = viewElementToggles.includes("insights")
      ? viewElementToggles.filter((t) => t !== "insights")
      : [...viewElementToggles, "insights"]

    dispatch(setDashboardViewToggles(newToggles))
  }

  // Show the view that was created with the given question id
  const handleShowView = (questionId) => {
    const view = views.find((v) => v.question_id === questionId)
    if (view) {
      handleSelectView({ viewId: view.id })
    }
  }

  const handleCreateSuggestedView = async (question) => {
    if (!IS_AIM_TRIAL && !aiRole) {
      setShowBillingDialog(true)
      return
    }

    if (viewSet.purpose === "" || viewSet.overview === "") {
      enqueueSnackbar("Please ensure 'objective' and 'context' are defined.", {
        variant: "warning",
      })
      return
    }

    console.log("question", question)

    setGeneratingMessage("Creating suggested view...")

    const suggestedView = await createSingleSuggestedView({
      assistants: assistants,
      overview: viewSet.overview,
      purpose: viewSet.purpose,
      question: question,
    })

    console.log("suggested view", {
      suggestedView,
      viewSuggestions,
      viewSet,
    })

    const suggestedViewWithQuestion = {
      ...suggestedView,
      question: question.question,
      // So we can crossref the question with the view
      question_id: question.question_id,
    }

    handleAddSuggestedView(suggestedViewWithQuestion)
    setGeneratingMessage(undefined)
  }

  const handleCreateContext = () => {
    if (!IS_AIM_TRIAL && !aiRole) {
      setShowBillingDialog(true)
      return
    }

    if (viewSet.purpose === "" || viewSet.overview === "") {
      enqueueSnackbar("Please ensure 'objective' and 'context' are defined.", {
        variant: "warning",
      })
      return
    }

    setGeneratingMessage("Researching context...")

    createViewSetContext({
      assistants,
      viewSet,
      roles,
      setMessage: setGeneratingMessage,
    }).then((result) => {
      console.log("view context", { result })
      setViewSuggestions({
        suggestedViews: viewSet.view_suggestions,
        viewContext: result,
        viewQuestions: viewSet.view_questions,
      })
      setGeneratingMessage(undefined)
    })
  }

  const handleSortQuestions = () => {
    sortQuestions({
      assistants,
      viewSet,
      setMessage: setGeneratingMessage,
      roles,
    }).then((result) => {
      setGeneratingMessage(undefined)
      console.log("sorted questions", { result })

      // Now sort questions in the specified order

      const newQuestions = viewSet.view_questions
        .sort((a, b) => {
          const indexA = result.questions.findIndex(
            (q) => q.question_id === a.question_id
          )
          const indexB = result.questions.findIndex(
            (q) => q.question_id === b.question_id
          )

          return indexA - indexB
        })
        .map((q) => {
          const sortInfo = result.questions.find(
            (sorted) => q.question_id === sorted.question_id
          ) || { sort_rationale: "", prequisites: [] }

          return {
            ...q,
            sort_rationale: sortInfo.sort_rationale,
            prerequisites: sortInfo.prerequisites,
          }
        })

      setViewSuggestions({
        suggestedViews: viewSet.view_suggestions,
        viewContext: viewSet.view_context,
        viewQuestions: newQuestions,
      })
    })
  }

  const handleSuggestNextBestQuestions = async () => {
    if (!IS_AIM_TRIAL && !aiRole) {
      setShowBillingDialog(true)
      return
    }

    if (viewSet.purpose === "" || viewSet.overview === "") {
      enqueueSnackbar("Please ensure 'objective' and 'context' are defined.", {
        variant: "warning",
      })
      return
    }

    setGeneratingMessage("Selecting next best questions...")
    suggestNextBestQuestions({
      assistants,
      viewSet,
      views,
      setMessage: setGeneratingMessage,
    }).then((extraSuggestedViews) => {
      setGeneratingMessage(undefined)
      console.log("next best questions", { extraSuggestedViews })

      if (extraSuggestedViews) {
        setViewSuggestions({
          suggestedViews: [...extraSuggestedViews, ...viewSuggestions],
          viewContext: viewSet.view_context,
          viewQuestions: viewSet.view_questions,
        })
        enqueueSnackbar(
          "Next best questions identified. Click Save if you wish to keep these.",
          {
            variant: "success",
          }
        )

        // Show suggested views tab

        dispatch(setModelEditDashboardTab(SUGGESTED_VIEWS_TAB))
      }
    })
  }

  const handleGenerateQuestions = async () => {
    //console.log("suggest view", { viewSet, views })

    if (!IS_AIM_TRIAL && !aiRole) {
      setShowBillingDialog(true)
      return
    }

    if (viewSet.purpose === "" || viewSet.overview === "") {
      enqueueSnackbar("Please ensure 'objective' and 'context' are defined.", {
        variant: "warning",
      })
      return
    }

    setGeneratingMessage("Creating questions and suggested views...")
    setPercentComplete(0)
    generateQuestions({
      assistants,
      viewSet: viewSet,
      views,
      setMessage: setGeneratingMessage,
      setPercentComplete: setPercentComplete,
      roles,
    }).then((result) => {
      setPercentComplete(0)
      setGeneratingMessage(undefined)
      console.log("suggested views", { result })
      if (result) {
        const pinnedQuestions =
          viewSet.view_questions?.filter((q) => q.pinned) || []
        const pinnedSuggestedViews =
          viewSet.view_suggestions?.filter((s) => s.pinned) || []

        // remove any questions that already exist in pinned questions based on the question text
        const additionalQuestions = result.viewQuestions.filter(
          (q) => !pinnedQuestions.find((pq) => pq.question === q.question)
        )

        // pinned questions plus new questions
        const newQuestions = [...pinnedQuestions, ...additionalQuestions].sort(
          (a, b) => {
            // Sort questions by layer, in order of 'Strategy', 'Business', 'Application', 'Technology', 'Implementation & Migration', and no layer goes last

            const layerOrder = [
              palette.LAYER_NAME_MOTIVATION,
              palette.LAYER_NAME_STRATEGY,
              palette.LAYER_NAME_BUSINESS,
              palette.LAYER_NAME_APPLICATION,
              palette.LAYER_NAME_TECHNOLOGY,
              palette.LAYER_NAME_PHYSICAL,
              palette.LAYER_NAME_IMPLEMENTATION,
            ]

            const layerA = a.layer || ""
            const layerB = b.layer || ""

            const indexA = layerOrder.indexOf(layerA)
            const indexB = layerOrder.indexOf(layerB)

            if (indexA === -1 && indexB === -1) {
              return 0
            }

            if (indexA === -1) {
              return 1
            }

            if (indexB === -1) {
              return -1
            }

            return indexA - indexB
          }
        )

        // pinned suggested views plus new suggested views
        const newSuggestedViews = [
          ...pinnedSuggestedViews,
          ...result.suggestedViews,
        ]

        setViewSuggestions({
          suggestedViews: newSuggestedViews,
          viewContext: result.viewContext,
          viewQuestions: newQuestions,
        })
        enqueueSnackbar(
          "Suggested views generated. Click Save if you wish to keep these.",
          {
            variant: "success",
          }
        )
      }
    })
  }

  const handleAddQuestion = () => {
    setQuestionToEdit({
      question_id: `question-${uuidv4()}`,
      question: "",
      rationale: "",
      layer: "",
      element_types: [],
      pinned: false,
    })

    setShowQuestionDialog(true)
  }

  const handleDeleteQuestion = (questionId) => {
    const newQuestions = viewSet.view_questions.filter(
      (q) => q.question_id !== questionId
    )

    setViewSuggestions({
      suggestedViews: viewSet.view_suggestions,
      viewContext: viewSet.view_context,
      viewQuestions: newQuestions,
    })
  }

  const handleSetPrompts = ({ name, prompts }) => {
    const newSuggestions = viewSuggestions.map((suggestion) => {
      if (suggestion.name === name) {
        return { ...suggestion, prompts: prompts }
      }
      return suggestion
    })

    setViewSuggestions({ suggestedViews: newSuggestions })
  }

  const formatMultiLineText = (text) => {
    const lines = text.split("\n").map((line, index) => {
      return (
        <Typography key={index} variant="body2" component="span">
          {line}
        </Typography>
      )
    })

    return <Stack gap={1}>{lines}</Stack>
  }

  return (
    <Box>
      {showBillingDialog && (
        <GetAIMAIDialog
          open={showBillingDialog}
          onClose={() => setShowBillingDialog(false)}
        />
      )}

      {showQuestionDialog && (
        <ModelEditQuestionDialog
          open={showQuestionDialog}
          onClose={() => setShowQuestionDialog(false)}
          handleSave={(question) => {
            const isNewQuestion = !viewSet.view_questions.find(
              (q) => q.question_id === question.question_id
            )

            const newQuestions = isNewQuestion
              ? [...viewSet.view_questions, question]
              : viewSet.view_questions.map((q) => {
                  if (q.question_id === question.question_id) {
                    return question
                  }
                  return q
                })

            setViewSuggestions({
              suggestedViews: viewSet.view_suggestions,
              viewContext: viewSet.view_context,
              viewQuestions: newQuestions,
            })

            setShowQuestionDialog(false)
          }}
          values={questionToEdit}
          setValues={setQuestionToEdit}
        />
      )}

      <TabContext value={selectedTabIndex}>
        <TabList
          value={selectedTabIndex}
          onChange={(e, newValue) => {
            dispatch(setModelEditDashboardTab(newValue))
            //console.log("update dashboard tab", { newValue })
          }}
          aria-label="Dashboard content"
        >
          <Tab label="Context" value={CONTEXT_TAB} />
          <Tab label="Questions" value={QUESTIONS_TAB} />
          <Tab label="Suggested Views" value={SUGGESTED_VIEWS_TAB} />
          <Tab label="My Views" value={MY_VIEWS_TAB} />
          <Tab label="Documents" value={DOCUMENTS_TAB} />
        </TabList>

        <TabPanel value={CONTEXT_TAB} sx={{ px: 0 }}>
          <Stack direction="column" gap={1}>
            <Stack
              direction="row"
              gap={1}
              sx={{ alignItems: "center", mb: "10px" }}
            >
              <CreateContextButton
                handleCreateContext={handleCreateContext}
                generatingMessage={generatingMessage}
                disabled={assistants.length === 0}
              />
              <GeneratingMessage generatingMessage={generatingMessage} />
            </Stack>
            <LabelAndValue
              label={
                <Typography component="span" fontWeight={"bold"}>
                  Objective
                </Typography>
              }
              value={
                viewSet.purpose || (
                  <Typography
                    component="span"
                    color="error"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    Enter an Objective to help tailor view suggestions.
                    <IconButton onClick={() => setShowConfigureViewSet(true)}>
                      <SettingsIcon color="error" />
                    </IconButton>
                  </Typography>
                )
              }
            />
            <LabelAndValue
              label={
                <Typography component="span" fontWeight={"bold"}>
                  Context
                </Typography>
              }
              value={
                viewSet.overview || (
                  <Typography
                    component="span"
                    color="error"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    Define context{" "}
                    <IconButton onClick={() => setShowConfigureViewSet(true)}>
                      <SettingsIcon color="error" />
                    </IconButton>
                  </Typography>
                )
              }
            />

            <Box sx={{ py: "5px" }}>
              <Divider />
            </Box>
            <LabelAndValue
              label={
                <Typography component="span" fontWeight={"bold"}>
                  Researched Context
                </Typography>
              }
              value=""
            />
            <Box
              sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
              gap={2}
            >
              {(viewSet.view_context === undefined ||
                (isViewContextInJSONFormat &&
                  viewSet.view_context.length === 0)) && (
                <Alert severity="info">
                  Click Research Context to expand upon your provided context.
                  This information is the included as context when generating
                  content.
                </Alert>
              )}

              {isViewContextInJSONFormat &&
                viewSet.view_context.map((item) => (
                  <ViewContextItem key={item.title} item={item} />
                ))}
              {!isViewContextInJSONFormat && (
                <MultiLineText text={viewSet?.view_context} />
              )}
            </Box>
          </Stack>
        </TabPanel>
        <TabPanel value={QUESTIONS_TAB} sx={{ px: 0 }}>
          <Stack direction="column" gap={2} sx={{ mb: "15px" }}>
            {!canAddView && !isPaid && (
              <Alert severity="info">
                A full subscription allows 50 questions to be generated. Go to
                Config | Subscription to upgrade.
              </Alert>
            )}
            <Stack
              direction="row"
              sx={{
                alignItems: "center",
              }}
              gap={1}
            >
              <CreateQuestionsButton
                handleGenerateQuestions={handleGenerateQuestions}
                generatingMessage={generatingMessage}
                disabled={assistants.length === 0}
              />

              <NextBestQuestionsButton
                handleSuggestNextBestQuestions={handleSuggestNextBestQuestions}
                generatingMessage={generatingMessage}
                disabled={assistants.length === 0}
              />

              {/* <SortQuestionsButton
                handleSortQuestions={handleSortQuestions}
                generatingMessage={generatingMessage}
                disabled={assistants.length === 0}
              /> */}

              <GeneratingMessage generatingMessage={generatingMessage} />
              {generatingMessage && percentComplete > 0 && (
                <Box sx={{ width: "100px" }}>
                  <LinearProgress
                    sx={{ height: 10 }}
                    variant="determinate"
                    value={percentComplete}
                  />
                </Box>
              )}
            </Stack>
            {/* {viewSuggestions && viewSuggestions.length === 0 && (
              <NoSuggestionsAlert />
            )} */}
          </Stack>

          <Box
            sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
            gap={2}
          >
            {/* {(viewSet.view_questions === undefined ||
              (isViewQuestionsInJSONFormat &&
                viewSet.view_questions.length === 0)) && (
              <Alert severity="info">
                Open the Suggested Views tab and click Generate to create a set
                of questions that are relevant to achieving your objective.
              </Alert>
            )} */}
            {isViewQuestionsInJSONFormat &&
              viewSet.view_questions.map((q) => (
                <ModelEditQuestion
                  key={q.question_id}
                  viewSet={viewSet}
                  views={views}
                  question={q}
                  isGenerating={Boolean(generatingMessage)}
                  handleCreateSuggestedView={handleCreateSuggestedView}
                  handleTogglePinQuestion={handleTogglePinQuestion}
                  handleShowView={handleShowView}
                  handleDeleteQuestion={() =>
                    handleDeleteQuestion(q.question_id)
                  }
                  disableCreateSuggestedView={assistants.length === 0}
                  selectedQuestionId={selectedQuestionId}
                  setSelectedQuestionId={setSelectedQuestionId}
                />
              ))}
            {!isViewQuestionsInJSONFormat && (
              <MultiLineText text={viewSet?.view_questions} />
            )}
            <Fab
              color="primary"
              aria-label="add"
              sx={styles.fab}
              onClick={handleAddQuestion}
            >
              <AddIcon />
            </Fab>
          </Box>
        </TabPanel>

        <TabPanel value={SUGGESTED_VIEWS_TAB} sx={{ px: 0 }}>
          <Stack direction="column" gap={2} sx={{ mb: "15px" }}>
            <ModelEditMaxViewsReached canAddView={canAddView} isPaid={isPaid} />
            <Stack
              direction="row"
              sx={{
                alignItems: "center",
              }}
              gap={1}
            >
              {/* <ClearSuggestionsButton
                handleClearViewSuggestions={handleClearViewSuggestions}
                generatingMessage={generatingMessage}
                viewSuggestions={viewSuggestions}
              /> */}

              <GeneratingMessage generatingMessage={generatingMessage} />
            </Stack>
            {/* {viewSuggestions && viewSuggestions.length === 0 && (
              <NoSuggestionsAlert />
            )} */}
          </Stack>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 2,
              marginTop: "10px",
            }}
          >
            {viewSuggestions &&
              viewSuggestions.map((suggestion) => (
                <ModelEditViewSuggestionCard
                  // Ideally each suggestion needs an id, for key to be truly unique, i.e. user can create 2 views with same name
                  key={`${suggestion.name}-${suggestion.question_id}`}
                  suggestion={suggestion}
                  setSuggestion={(newSuggestion) => {
                    const newSuggestions = viewSuggestions.map((suggestion) => {
                      if (suggestion.name === newSuggestion.name) {
                        return newSuggestion
                      }
                      return suggestion
                    })
                    setViewSuggestions({ suggestedViews: newSuggestions })
                  }}
                  viewSet={viewSet}
                  views={views}
                  roles={roles}
                  canAddView={canAddView}
                  prompts={suggestion.prompts || []}
                  setPrompts={(prompts) =>
                    handleSetPrompts({
                      name: suggestion.name,
                      prompts: prompts,
                    })
                  }
                  handleCreateViewFromSuggestion={
                    handleCreateViewFromSuggestion
                  }
                  handleDeleteViewSuggestion={handleDeleteViewSuggestion}
                  generatingContentMessage={generatingMessage}
                  setGeneratingContentMessage={setGeneratingMessage}
                  handleTogglePinSuggestedView={handleTogglePinSuggestedView}
                />
              ))}
          </Box>
        </TabPanel>

        <TabPanel value={MY_VIEWS_TAB} sx={{ px: 0 }}>
          <Stack direction="column" gap={2}>
            <ModelEditMaxViewsReached canAddView={canAddView} isPaid={isPaid} />
            <Stack direction="row" gap={2} sx={{ alignItems: "center" }}>
              <CheckModellingCompletenessButton
                handleCheckModellingGoalCompleteness={
                  handleCheckModellingGoalCompleteness
                }
                generatingMessage={generatingMessage}
                disabled={assistants.length === 0}
              />
              <Tooltip title="Organize your views into a logical sequence for presenting, based on your objective">
                <span>
                  <Button
                    onClick={handleSortViewsForPresentation}
                    variant="outlined"
                    sx={{ textTransform: "none" }}
                    disabled={
                      assistants.length === 0 || Boolean(generatingMessage)
                    }
                    endIcon={<SortIcon sx={{ color: aiColor }} />}
                  >
                    Sort
                  </Button>
                </span>
              </Tooltip>
              <Tooltip title="Create document">
                <span>
                  <Button
                    onClick={async () => {
                      const doc = documentGenerationServices.generateDocument({
                        viewSet,
                        views,
                      })

                      // Pack the document into a blob using Packer
                      const blob = await Packer.toBlob(doc)

                      // create a safe file name using the viewSet name and remove any characters that are not allowed in file names for word docs
                      const fileName = viewSet.name.replace(/[^a-z0-9]/gi, "_")

                      // Use file-saver to save the file
                      saveAs(blob, `${fileName}.docx`)
                    }}
                    variant="outlined"
                    sx={{ textTransform: "none" }}
                    disabled={
                      assistants.length === 0 || Boolean(generatingMessage)
                    }
                    endIcon={<DownloadIcon />}
                  >
                    Create Document
                  </Button>
                </span>
              </Tooltip>
              {/* <ShowPromptsSwitch dashboardPrompts={dashboardPrompts} /> */}

              <ToggleViewElements viewElementToggles={viewElementToggles} />

              <GeneratingMessage generatingMessage={generatingMessage} />
            </Stack>
            <Stack direction="column" gap={2}>
              {modellingCompleteness && (
                <>
                  <Divider />
                  <LabelAndValue
                    label="Completion %"
                    value={modellingCompleteness.completion_percentage}
                  />
                  <LabelAndValue
                    label="Progress"
                    value={formatMultiLineText(
                      modellingCompleteness.completion_progress
                    )}
                    lineHeight={1.9}
                  />
                  <LabelAndValue
                    label="Next Steps"
                    value={formatMultiLineText(
                      modellingCompleteness.completion_recommendation
                    )}
                    lineHeight={1.9}
                  />
                </>
              )}
            </Stack>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                marginTop: "10px",
                flexWrap: "wrap",
                alignItems: "flex-start",
                gap: 2,
              }}
            >
              {views &&
                views
                  .sort((a, b) => a.sort_order - b.sort_order)
                  .map((view) => (
                    <ModelEditDashboardCard
                      key={view.id}
                      view={view}
                      handleSelectView={handleSelectView}
                      viewElementToggles={viewElementToggles}
                      handleShowInsights={handleShowInsights}
                      handleGenerateInsightsForSingleView={
                        handleGenerateInsightsForSingleView
                      }
                      setGeneratingContentMessage={setGeneratingMessage}
                      generatingMessage={generatingMessage}
                      aiRole={aiRole}
                      setShowBillingDialog={setShowBillingDialog}
                    />
                  ))}

              {views?.length === 0 && (
                <Alert severity="info">
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    To create a view click the{" "}
                    <Box
                      sx={{
                        fontWeight: "bold",
                        marginLeft: "5px",
                        marginRight: "5px",
                      }}
                    >
                      Add view
                    </Box>{" "}
                    button <AddIcon />
                  </Box>
                </Alert>
              )}
            </Box>
          </Stack>
        </TabPanel>
        <TabPanel value={DOCUMENTS_TAB} sx={{ px: 0 }}>
          <ModelEditDashboardDocumentsTab
            accountId={viewSet.account_id}
            viewSetId={viewSetId}
            viewSet={viewSet}
            views={views}
            setValues={setValues}
            handleAddSuggestedView={handleAddSuggestedView}
          />
        </TabPanel>
      </TabContext>
    </Box>
  )
}

const GeneratingMessage = ({ generatingMessage }) => {
  return (
    <>
      {generatingMessage && (
        <>
          <CircularProgress size={20} sx={{ marginLeft: "10px" }} />
          <Typography variant="caption" sx={{ marginLeft: "10px" }}>
            {generatingMessage}
          </Typography>
        </>
      )}
    </>
  )
}

const MultiLineText = ({ text }) => {
  return (
    text && (
      <Stack gap={1} component="span">
        {text.split("\n").map((item, key) => {
          return (
            <Typography key={key} variant="body2" component="span">
              {item}
            </Typography>
          )
        })}
      </Stack>
    )
  )
}

const CreateQuestionsButton = ({
  handleGenerateQuestions,
  generatingMessage,
  disabled,
}) => {
  return (
    <Tooltip title="Generate questions that are important to achieving your objective.">
      <span>
        <Button
          onClick={handleGenerateQuestions}
          variant="outlined"
          sx={{ textTransform: "none" }}
          disabled={disabled || Boolean(generatingMessage)}
          endIcon={<AssistantIcon sx={{ color: aiColor }} />}
        >
          Generate
        </Button>
      </span>
    </Tooltip>
  )
}

const NextBestQuestionsButton = ({
  handleSuggestNextBestQuestions,
  generatingMessage,
  disabled,
}) => {
  return (
    <Tooltip title="Select next best questions to achieving your objective.">
      <span>
        <Button
          onClick={handleSuggestNextBestQuestions}
          variant="outlined"
          sx={{ textTransform: "none" }}
          disabled={disabled || Boolean(generatingMessage)}
          endIcon={<SearchIcon sx={{ color: aiColor }} />}
        >
          Next Best Questions
        </Button>
      </span>
    </Tooltip>
  )
}

const SortQuestionsButton = ({
  handleSortQuestions,
  generatingMessage,
  disabled,
}) => {
  return (
    <Tooltip title="Sort questions in a way releevant to achieving your objective.">
      <span>
        <Button
          onClick={handleSortQuestions}
          variant="outlined"
          sx={{ textTransform: "none" }}
          disabled={disabled || Boolean(generatingMessage)}
          endIcon={<GradingIcon sx={{ color: aiColor }} />}
        >
          Sort
        </Button>
      </span>
    </Tooltip>
  )
}

const CreateContextButton = ({
  handleCreateContext,
  generatingMessage,
  disabled,
}) => {
  return (
    <Tooltip title="Generate context information">
      <span>
        <Button
          onClick={handleCreateContext}
          variant="outlined"
          sx={{ textTransform: "none" }}
          disabled={disabled || Boolean(generatingMessage)}
          endIcon={<TravelExploreIcon sx={{ color: aiColor }} />}
        >
          Research Context
        </Button>
      </span>
    </Tooltip>
  )
}

const CheckModellingCompletenessButton = ({
  handleCheckModellingGoalCompleteness,
  generatingMessage,
  disabled,
}) => {
  return (
    <Tooltip title="Analyse progress towards your objective">
      <span>
        <Button
          onClick={handleCheckModellingGoalCompleteness}
          disabled={disabled || Boolean(generatingMessage)}
          variant="outlined"
          sx={{ textTransform: "none" }}
          endIcon={<SportsScoreIcon sx={{ color: aiColor }} />}
        >
          Check Progress
        </Button>
      </span>
    </Tooltip>
  )
}

const ClearSuggestionsButton = ({
  handleClearViewSuggestions,
  generatingMessage,
  viewSuggestions,
}) => {
  return (
    <Tooltip title="Clear all suggested views">
      <span>
        <Button
          onClick={handleClearViewSuggestions}
          variant="outlined"
          sx={{ textTransform: "none" }}
          disabled={
            Boolean(generatingMessage) ||
            !viewSuggestions ||
            viewSuggestions.length === 0
          }
          endIcon={<ClearIcon />}
        >
          Clear
        </Button>
      </span>
    </Tooltip>
  )
}

/**
 * Items that can be toggled for each view are:
 * - prompts
 * - actions
 * - insights
 * @param {*} viewElementToggles The object holding the state of the toggles
 */
const ToggleViewElements = ({ viewElementToggles }) => {
  const dispatch = useDispatch()

  return (
    <ToggleButtonGroup
      value={viewElementToggles}
      onChange={(e, newValue) => {
        dispatch(setDashboardViewToggles(newValue))
      }}
    >
      <Tooltip title="Toggle prompts">
        <ToggleButton
          size="small"
          value="prompts"
          sx={{ textTransform: "none" }}
        >
          <NotesIcon />
        </ToggleButton>
      </Tooltip>
      <Tooltip title="Toggle insights and actions">
        <ToggleButton
          size="small"
          value="insights"
          sx={{ textTransform: "none" }}
        >
          <InsightsIcon />
        </ToggleButton>
      </Tooltip>
      <Tooltip title="Toggle properties">
        <ToggleButton
          size="small"
          value="properties"
          sx={{ textTransform: "none" }}
        >
          <SellIcon />
        </ToggleButton>
      </Tooltip>
      <Tooltip title="Toggle how to read diagram">
        <ToggleButton
          size="small"
          value="how_to_read"
          sx={{ textTransform: "none" }}
        >
          <HelpOutlineIcon />
        </ToggleButton>
      </Tooltip>
    </ToggleButtonGroup>
  )
}

// const ShowPromptsSwitch = ({ dashboardPrompts }) => {
//   const dispatch = useDispatch()

//   return (
//     <Tooltip title="Show the prompts used to create view">
//       <FormControlLabel
//         sx={{ marginLeft: "10px" }}
//         control={
//           <Switch
//             size="small"
//             checked={dashboardPrompts}
//             onChange={() =>
//               dispatch(setShowDashboardPrompts(!dashboardPrompts))
//             }
//           />
//         }
//         label={
//           <Typography
//             variant="caption"
//             color="textSecondary"
//             component={"span"}
//           >
//             Show Prompts
//           </Typography>
//         }
//       />
//     </Tooltip>
//   )
// }

// const NoSuggestionsAlert = () => {
//   return (
//     <Alert severity="info" sx={{ mt: "30px" }}>
//       <Box sx={{ display: "flex", alignItems: "center" }}>
//         Click <AssistantIcon sx={{ color: aiColor, ml: "2px", mr: "2px" }} /> to
//         generate Context, Questions, and Suggested Views in support of your
//         Objective.
//       </Box>
//     </Alert>
//   )
// }

const ViewContextItem = ({ item }) => {
  const text = item.facts || item.rationale

  // Check if the text is a series of numbered items (e.g. "1. some point 2. another point 3. yet another point") all on 1 line, and if so split them into individual lines

  const isNumberedItems = text.match(/^\d+\.\s/g)

  let lines = [text]
  if (isNumberedItems) {
    lines = text.split(/\d+\.\s/g).filter((line) => line.trim() !== "")
  }
  return (
    <Card sx={{ maxWidth: "320px" }}>
      <CardHeader title={item.title} subheader={item.role} />
      <CardContent>
        <Stack gap={1}>
          {lines.map((line, index) => (
            <Typography key={index} variant="body2" color="text.secondary">
              {line}
            </Typography>
          ))}
        </Stack>
      </CardContent>
    </Card>
  )
}

export default ModelEditDashboard
