import {
  Autocomplete,
  Box,
  Button,
  List,
  Stack,
  TextField,
  Tooltip,
  Divider,
  Typography,
} from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import ClearIcon from "@mui/icons-material/Clear"
import UploadFileIcon from "@mui/icons-material/UploadFile"
import { useEffect, useRef, useState } from "react"
import DeleteIcon from "@mui/icons-material/Delete"
import { saveAs } from "file-saver"
import EditIcon from "@mui/icons-material/Edit"
import db from "../Firestore"
import { serverTimestamp } from "../pages/services/dataServices"
import FileItem from "./FileItem"
import Heading from "./controls/Heading"
import TemplateInstanceHeadings from "./TemplateInstanceHeadings"
import * as dataServices from "../pages/services/dataServices"
import {
  createOpenAIFile,
  deleteOpenAIFile,
} from "../pages/services/cloudFunctions"
import { useSnackbar } from "notistack"
import YesNo from "./YesNo"
import { getUrl, uploadFiles } from "../pages/services/fileServices"
import { deleteFile } from "../pages/services/modelServices"
import useAssistants from "./useAssistants"
import Tiptap from "./tiptap/Tiptap"
//import { markdownToTiptapJSON } from "./tiptap/markdownConverter"

const styles = {
  input: {},
}

const ModelEditDashboardDocumentsTab = ({
  accountId,
  viewSetId,
  viewSet,
  views,
  setValues,
  handleAddSuggestedView,
}) => {
  const [templateSelection, setTemplateSelection] = useState([])

  const [templateOptions, setTemplateOptions] = useState([])

  const [templateInstances, setTemplateInstances] = useState([])

  const [templateInstance, setTemplateInstance] = useState()

  const assistants = useAssistants()

  const addFileRef = useRef()

  const { enqueueSnackbar } = useSnackbar()

  const [yesNoDeleteFileConfig, setYesNoDeleteFileConfig] = useState({
    title: "Delete",
    openPrompt: false,
    description: "Delete?",

    // Callback if user clicks 'Yes' to delete a child record.
    // We set the callback and label depending on what the user is deleting
    handleConfirm: null,
  })

  // Liaten for template_instances changes
  useEffect(() => {
    if (accountId) {
      const unsub = db
        .collection("template_instances")
        .where("account_id", "==", accountId)
        .where("parent_type", "==", "view_set")
        .where("parent_id", "==", viewSetId)
        .onSnapshot((querySnapshot) => {
          const templateInstances = []
          querySnapshot.forEach((doc) => {
            templateInstances.push({ id: doc.id, ...doc.data() })
          })
          setTemplateInstances(templateInstances)
        })
      return () => unsub()
    }
  }, [accountId, viewSetId])

  useEffect(() => {
    if (accountId) {
      db.collection("templates")
        .where("account_id", "==", accountId)
        .get()
        .then((querySnapshot) => {
          const templates = []
          querySnapshot.forEach((doc) => {
            templates.push({ id: doc.id, label: doc.data().name })
          })
          setTemplateOptions(templates)
        })
    }
  }, [accountId])

  const handlePromptConfirmDelete = (templateInstanceToDelete) => {
    setYesNoDeleteFileConfig({
      title: `Delete Document? ${templateInstanceToDelete.name}`,
      openPrompt: true,
      description: "This delete is permanent. Are you sure?",
      handleConfirm: () =>
        handleDeletePromptConfirmed(templateInstanceToDelete),
    })
  }

  const handleUploadFileToOpenAI = async () => {
    // Get first file in the list, if any

    if (viewSet.docs && viewSet.docs.length > 0) {
      const doc = viewSet.docs[0]

      console.log("%c[create file] create openai file", "color:yellow", { doc })

      // Load document from cloud storage
      const folderPath = `accounts/${viewSet.account_id}/view_sets/${viewSetId}`
      const fullPath = `${folderPath}/${doc.name}`

      createOpenAIFile({ fileStoragePath: fullPath }).then((result) => {
        console.log("create file result", { result })

        // Update file id into docs array

        const newDocs = viewSet.docs.map((d) => {
          if (d.name === doc.name) {
            return { ...d, file_id: result.data.response.id }
          } else {
            return d
          }
        })

        console.log("%cnewDocs", "color:orange", { newDocs })

        setValues({ ...viewSet, docs: newDocs })

        db.collection("view_sets")
          .doc(viewSetId)
          .update(
            { docs: newDocs, modified: dataServices.serverTimestamp() },
            { merge: true }
          )
          .then(() => {
            enqueueSnackbar("Uploaded to OpenAI", { variant: "success" })
          })
      })
    }
  }

  const handleRemoveFileFromOpenAI = async (doc) => {
    console.log("Removing file from OpenAI", { doc })

    if (doc.file_id) {
      deleteOpenAIFile({ fileId: doc.file_id }).then((result) => {
        console.log("delete file result", { result })

        // Update file id into docs array
        const newDocs = viewSet.docs.map((d) => {
          if (d.name === doc.name) {
            // remove file_id attribute
            const { file_id, ...rest } = d
            return rest
          } else {
            return d
          }
        })

        setValues({ ...viewSet, docs: newDocs })

        db.collection("view_sets")
          .doc(viewSetId)
          .update({ docs: newDocs, modified: dataServices.serverTimestamp() })
          .then(() => {
            enqueueSnackbar("Removed from OpenAI", { variant: "success" })
          })
      })
    }
  }

  const handleDeleteFile = async (name) => {
    console.log("handleDeleteFile", { name })
    const newDocs = viewSet.docs.filter((doc) => doc.name !== name)

    setValues({ ...viewSet, docs: newDocs })

    const folderPath = `accounts/${viewSet.account_id}/view_sets/${viewSetId}`
    await deleteFile(folderPath, name)

    if (viewSetId) {
      db.collection("view_sets")
        .doc(viewSetId)
        .update({ docs: newDocs, modified: dataServices.serverTimestamp() })
        .then(() => {
          enqueueSnackbar("Deleted", { variant: "success" })
        })
    }
  }

  const handleAddFile = async (event) => {
    const filePath = `accounts/${viewSet.account_id}/view_sets/${viewSetId}/`
    const files = []
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i]
      files.push(file)
    }

    console.log("%cuploading files", "color:lightgreen", { files, filePath })
    await uploadFiles(files, filePath)

    // Merge files into the values.docs array, with each element having a name, and date uploaded. Deduplicate by name.

    const newDocs = viewSet.docs || []

    files.forEach((file) => {
      const doc = {
        name: file.name,
      }

      newDocs.push(doc)
    })

    // Update docs in state, and also in DB

    setValues({ ...viewSet, docs: newDocs })

    if (viewSetId) {
      db.collection("view_sets")
        .doc(viewSetId)
        .update({ docs: newDocs, modified: dataServices.serverTimestamp() })
        .then(() => {
          enqueueSnackbar("Uploaded", { variant: "success" })
        })
    }

    addFileRef.current.value = null
  }

  const handleDownload = async (index) => {
    const doc = viewSet.docs[index]
    const folderPath = `accounts/${viewSet.account_id}/vieww_sets/${viewSetId}/`
    const url = await getUrl(folderPath, doc.name)
    saveAs(url, doc.name)
  }

  const handleUpload = (event) => {
    event.preventDefault()
    addFileRef.current.click()
  }

  const handlePromptDeleteFile = async (index) => {
    const newYesNoDeleteFileConfig = {
      ...yesNoDeleteFileConfig,
      title: "Delete File?",
      description: `Delete file ${viewSet.docs[index].name}?`,
      openPrompt: true,
      handleConfirm: () => handleDeleteFile(viewSet.docs[index].name),
    }

    setYesNoDeleteFileConfig(newYesNoDeleteFileConfig)
  }

  const hideDeleteFilePrompt = () => {
    const newConfig = {
      ...yesNoDeleteFileConfig,
      openPrompt: false,
    }
    setYesNoDeleteFileConfig(newConfig)
  }

  const handleDeletePromptConfirmed = async (templateInstanceToDelete) => {
    hideDeleteFilePrompt()

    console.log("Deleting", templateInstanceToDelete)

    if (templateInstanceToDelete) {
      // Delete template
      db.collection("template_instances")
        .doc(templateInstanceToDelete.id)
        .delete()
        .then(() => {
          enqueueSnackbar("Deleted", { variant: "success" })
        })
        .then(() => {
          if (templateInstanceToDelete.id === templateInstance?.id) {
            setTemplateInstance(undefined)
          }
        })
    }
  }

  const handleSaveHeadingContent = async ({
    selectedHeading,
    headingContent,
  }) => {
    const newTemplateInstance = templateInstance.content.map((heading) => {
      if (heading.level === selectedHeading.level) {
        return { ...heading, content: headingContent }
      } else {
        return heading
      }
    })

    setTemplateInstance({ ...templateInstance, content: newTemplateInstance })

    db.collection("template_instances")
      .doc(templateInstance.id)
      .update({
        content: newTemplateInstance,
        modified: serverTimestamp(),
      })
      .then(() => {
        enqueueSnackbar("Saved", { variant: "success" })
      })
  }

  const handleAddTemplates = async () => {
    for (const selection of templateSelection) {
      console.log("Adding template", selection)

      const templateData = await db
        .collection("templates")
        .doc(selection.id)
        .get()
      const template = templateData.data()

      const newTemplateInstance = {
        account_id: accountId,
        template_id: selection.id,
        name: template.name,
        content: template.headings,
        parent_type: "view_set",
        parent_id: viewSetId,
        created: serverTimestamp(),
        modified: serverTimestamp(),
      }

      console.log("%cadding template", "color:lightgreen", newTemplateInstance)

      db.collection("template_instances")
        .add(newTemplateInstance)
        .then((docRef) => {
          console.log("Document written with ID: ", docRef.id)
        })
        .catch((error) => {
          console.error("Error adding document: ", error)
        })
    }
  }

  const handleSelectFile = (file) => {
    console.log("Selected file", file)
    setTemplateInstance(file)
  }

  return (
    <>
      <YesNo config={yesNoDeleteFileConfig} />

      <Stack gap={1}>
        <Box>
          <Heading>Input Files</Heading>
        </Box>

        {!viewSet.docs || viewSet.docs.length === 0 ? (
          <Typography variant="body2">No files uploaded currently</Typography>
        ) : null}

        <List dense>
          {viewSet.docs &&
            viewSet.docs.map((doc, index) => (
              <FileItem
                key={doc.name}
                fileName={doc.name}
                fileIndex={index}
                handlePromptDeleteFile={handlePromptDeleteFile}
                handleDownload={handleDownload}
                showSecondaryText={() => {
                  return doc.file_id ? (
                    <Typography variant="caption" color="primary">
                      Loaded in OpenAI
                    </Typography>
                  ) : (
                    <Typography color="grey" variant="caption">
                      Not in OpenAI
                    </Typography>
                  )
                }}
                extraMenuItems={[
                  {
                    label: "Edit",
                    icon: <EditIcon />,
                    onClick: () => console.log("Edit", index),
                  },
                  {
                    label: "Delete",
                    icon: <DeleteIcon />,
                    onClick: () =>
                      handlePromptConfirmDelete(templateInstances[index]),
                  },
                  {
                    icon: <UploadFileIcon />,
                    label: "Upload to OpenAI",
                    disabled: Boolean(doc.file_id),
                    onClick: () => {
                      console.log("uploading to OpenAI", { doc })
                      handleUploadFileToOpenAI(doc)
                    },
                  },
                  {
                    icon: <ClearIcon />,
                    label: "Remove from OpenAI",
                    disabled: Boolean(!doc.file_id),
                    onClick: () => {
                      console.log("Remove file from OpenAI", { doc })
                      handleRemoveFileFromOpenAI(doc)
                    },
                  },
                ]}
              />
            ))}
        </List>

        <Box sx={{ py: "5px" }}>
          <Button
            type="button"
            variant="outlined"
            tooltip="Upload a file"
            endIcon={<CloudUploadIcon />}
            onClick={handleUpload}
          >
            Upload
          </Button>
        </Box>

        <input
          hidden
          accept="*/*"
          sx={styles.input}
          id="icon-button-file"
          type="file"
          capture="environment"
          ref={addFileRef}
          onChange={(e) => handleAddFile(e)}
        />

        <Box>
          <Divider />
        </Box>

        <Heading>Output Documents</Heading>

        <List dense>
          {templateInstances &&
            templateInstances.map((doc, index) => (
              <FileItem
                key={doc.name}
                fileName={doc.name}
                fileIndex={index}
                //handlePromptDeleteFile={handlePromptDeleteFile}
                //handleDownload={handleDownload}
                handleSelectFile={(index) =>
                  handleSelectFile(templateInstances[index])
                }
                extraMenuItems={[
                  {
                    label: "Delete",
                    icon: <DeleteIcon />,
                    onClick: () =>
                      handlePromptConfirmDelete(templateInstances[index]),
                  },
                ]}
              />
            ))}
        </List>

        <Stack direction="row" gap={1}>
          <Tooltip title="Add a template, and then populate it with content from views">
            <span>
              <Button
                onClick={handleAddTemplates}
                variant="outlined"
                sx={{ textTransform: "none" }}
                endIcon={<AddIcon />}
              >
                Add Template(s)
              </Button>
            </span>
          </Tooltip>
          <Box sx={{ minWidth: "300px" }}>
            {templateOptions.length > 0 && (
              <Autocomplete
                multiple
                id="template"
                options={templateOptions}
                size="small"
                value={templateSelection}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(e, newValue) => {
                  setTemplateSelection(newValue)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Template"
                    variant="outlined"
                    size="small"
                    sx={{ maxWidth: "300px" }}
                  />
                )}
              />
            )}
          </Box>
        </Stack>

        {/* <Box>
          <Tiptap content={markdownToTiptapJSON("- Hello")} />
        </Box> */}

        <Heading>Selected Document</Heading>
        <Typography variant="h6">{templateInstance?.name}</Typography>
        {templateInstance && (
          <TemplateInstanceHeadings
            templateInstance={templateInstance}
            viewSet={viewSet}
            views={views}
            assistants={assistants}
            handleSaveHeadingContent={handleSaveHeadingContent}
            handleAddSuggestedView={handleAddSuggestedView}
          />
        )}
      </Stack>
    </>
  )
}

export default ModelEditDashboardDocumentsTab
